import React from 'react';
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";
import Dropdown from "react-bootstrap/Dropdown";
import { FiFilePlus, FiUpload, FiPrinter, FiBookOpen, FiFolder } from "react-icons/fi";

const NavTabs = (props) => (
  <div>

    <Nav variant="tabs" className="nav-tabs">
      <Nav.Item style={{display: "QuickView" in props.auth ? '' : 'none' }}>
        <Nav.Link onClick={ () => props.rootProps("/records") } as={NavLink}>
          <FiFolder/>
          <span>DOCUMENTS</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item style={{display: "NewFiles" in props.auth ? '' : 'none' }}>
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink} className="navbar-dropdowns">
            <FiFilePlus />
            <span>NEW FILES</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={ () => props.rootProps("/n/s") }>Summons</Dropdown.Item>
            <Dropdown.Item onClick={ () => props.rootProps("/n/r") }>Sheriff Returns</Dropdown.Item>
            <Dropdown.Item onClick={ () => props.rootProps("/n/j") }>Judgement</Dropdown.Item>
            <Dropdown.Item onClick={ () => props.rootProps("/n/o") }>Other</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </Nav.Item>
      <Nav.Item style={{display: "FileUpdate" in props.auth ? '' : 'none' }}>
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink} className="navbar-dropdowns">
            <FiUpload className="" />
            <span>FILE UPDATE</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={ () => props.rootProps("/u/s") }>Summons</Dropdown.Item>
            <Dropdown.Item onClick={ () => props.rootProps("/u/r") }>Sheriff Returns</Dropdown.Item>
            <Dropdown.Item onClick={ () => props.rootProps("/u/j") }>Judgement</Dropdown.Item>
            <Dropdown.Item onClick={ () => props.rootProps("/u/o") }>Other</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Item>
      <Nav.Item style={{display: "Reports" in props.auth ? '' : 'none' }}>
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink} className="navbar-dropdowns">
            <FiPrinter className="" />
            <span>REPORTS</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={ () => props.rootProps("/r/s") }>Summons</Dropdown.Item>
            <Dropdown.Item onClick={ () => props.rootProps("/r/r") }>Sheriff Returns</Dropdown.Item>
            <Dropdown.Item onClick={ () => props.rootProps("/r/j") }>Judgement</Dropdown.Item>
            <Dropdown.Item onClick={ () => props.rootProps("/r/o") }>Other</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Item>
      <Nav.Item style={{display: "Management" in props.auth ? '' : 'none' }}>
        <Nav.Link onClick={ () => props.rootProps("/m") } as={NavLink}>
          <FiBookOpen/>
          <span>MANAGEMENT</span>
        </Nav.Link>
      </Nav.Item>
    </Nav>

  </div>
);

export default NavTabs;
