import { Storage } from "aws-amplify";

export async function s3Upload(file) {
  const filename = file.name;

  const stored = await Storage.put(filename, file.file, {
    contentType: file.type,
  });

  return stored.key;
}
