const dev = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "profast-api-dev-attachmentsbucket-15wcw8r0v2rkf",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://68d37s48f8.execute-api.us-west-2.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_SwKmIuMEs",
    APP_CLIENT_ID: "15k5t4pl0lc5mhp5g8h81d9lj",
    IDENTITY_POOL_ID: "us-west-2:4fafcd41-457f-40f9-918a-ae0a99d4fa9d",
  },
};
// const dev = {
//   s3: {
//     REGION: "us-west-2",
//     BUCKET: "profast-api-prod-attachmentsbucket-ouz9t123yraa",
//   },
//   apiGateway: {
//     REGION: "us-west-2",
//     URL: "https://d341s820mb.execute-api.us-west-2.amazonaws.com/prod",
//   },
//   cognito: {
//     REGION: "us-west-2",
//     USER_POOL_ID: "us-west-2_Qs0v2PqRX",
//     APP_CLIENT_ID: "vjug4307nt81qdaaguppg6ds2",
//     IDENTITY_POOL_ID: "us-west-2:cc2d1a36-a7ce-4c8c-890e-bfc947516936",
//   },
// };

const prod = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "profast-api-prod-attachmentsbucket-ouz9t123yraa",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://d341s820mb.execute-api.us-west-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_Qs0v2PqRX",
    APP_CLIENT_ID: "vjug4307nt81qdaaguppg6ds2",
    IDENTITY_POOL_ID: "us-west-2:cc2d1a36-a7ce-4c8c-890e-bfc947516936",
  },
};
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default config;
