import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/home/Home";
import Login from "./containers/auth/Login";
// import Signup from "./containers/auth/Signup";
import NotFound from "./containers/auth/NotFound";

import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import QuickView from "./containers/quickView/QuickView";
import NewSummons from "./containers/new/Summons";
import NewSheriff from "./containers/new/Sheriff";
import NewJudgement from "./containers/new/Judgement";
import NewOther from "./containers/new/Other";

import UpdateSummons from "./containers/update/Summons";
import UpdateSheriff from "./containers/update/Sheriff.js";
import UpdateJudgement from "./containers/update/Judgement.js";
import UpdateOther from "./containers/update/Other.js";

import ReportSummons from "./containers/report/Summons";
import ReportJudgement from "./containers/report/Judgement.js";
import ReportSheriff from "./containers/report/Sheriff.js";
import ReportOther from "./containers/report/Other.js";

import Management from "./containers/management/Management.js";

import ItemView from "./containers/item/ItemView.js";
import ItemViewHidden from "./containers/item/ItemViewHidden.js";

const routes = ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={NotFound} />
    <AuthenticatedRoute path="/home" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    {/*<UnauthenticatedRoute path="/signupnewuser" exact component={Signup} props={childProps} />*/}

    <AuthenticatedRoute path="/records" exact component={QuickView} props={childProps} />
    <AuthenticatedRoute path="/n/s" exact component={NewSummons} props={childProps} />
    <AuthenticatedRoute path="/n/r" exact component={NewSheriff} props={childProps} />
    <AuthenticatedRoute path="/n/j" exact component={NewJudgement} props={childProps} />
    <AuthenticatedRoute path="/n/o" exact component={NewOther} props={childProps} />

    <AuthenticatedRoute path="/u/s" exact component={UpdateSummons} props={childProps} />
    <AuthenticatedRoute path="/u/r" exact component={UpdateSheriff} props={childProps} />
    <AuthenticatedRoute path="/u/j" exact component={UpdateJudgement} props={childProps} />
    <AuthenticatedRoute path="/u/o" exact component={UpdateOther} props={childProps} />

    <AuthenticatedRoute path="/r/s" exact component={ReportSummons} props={childProps} />
    <AuthenticatedRoute path="/r/j" exact component={ReportJudgement} props={childProps} />
    <AuthenticatedRoute path="/r/r" exact component={ReportSheriff} props={childProps} />
    <AuthenticatedRoute path="/r/o" exact component={ReportOther} props={childProps} />


    <AuthenticatedRoute path="/m" exact component={Management} props={childProps} />
    <AuthenticatedRoute path="/item" exact component={ItemView} props={childProps} />
    <AuthenticatedRoute path="/itm" exact component={ItemViewHidden} props={childProps} />

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;


export default routes;
