import React from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { FiPlusCircle, FiMinusCircle, FiSave } from "react-icons/fi";

const Process = (props) => (
  <div style={{marginLeft: "1rem", display: "flex"}}>

    <Table striped bordered hover size="sm" responsive="sm" className="man-scroll-table man-table">
      <thead>
        <tr>
          <th style={{width: "5rem", textAlign: "center"}}>ID</th>
          <th style={{width: "30rem"}}>Process Name</th>
          <th style={{width: "5rem", textAlign: "center"}}>Is Judgement</th>
          <th style={{width: "5rem", textAlign: "center"}}>Delete</th>
        </tr>
      </thead>
      <tbody>

        {props.state.process.map((row, index) => (
          <tr key={index} style={{ display: index !== 0 ? "" : "none" }}>
            <td style={{width: "5rem", textAlign: "center"}} className="pt-2">
              {row.id}
            </td>

            <td>
              <Form.Control id="name" type="text" value={row.name}
                onChange={e => props.onChanged(index, e.target.value, "process")} />
            </td>

            <td style={{width: "5rem", textAlign: "center"}}>
              <Form.Check id={`check-${index}`} custom type="checkbox" className="m-2" checked={row.isJudgement}
                onClick={e => props.onChanged(index, e.target.value, "process")}/>
            </td>

            <td style={{width: "5rem", textAlign: "center", color: "#dc0d0e", fontSize: "1.5rem"}}>
              <FiMinusCircle  style={{fontSize: "1.5rem", cursor: "pointer"}} className="mt-1"
                onClick={event => props.onChanged(index, "delete", "process")}/>
            </td>
          </tr>
        ))}

      </tbody>
    </Table>

    <FiPlusCircle className="add-man-button ml-4" onClick={e => props.onAddEntry("process")}/>

    <FiSave className="save-man-button ml-4" onClick={e => props.onSaveEntry("process")}
      style={{display: props.changed ? "" : "none"}}/>


  </div>
);

export default Process;
