import React, { Component } from 'react';
import NavTabs from "./NavTabs";
import "./Home.css";
import Background from "../img/profast.png";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { API } from 'aws-amplify';

import * as backendApi from '../../services/api';

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      base64: null,
      email: "",
      barcodes: [],
      count: 0,
      clientList: [],
      barcodesCase: {
      }
    };

  }

  async componentDidMount() {
    this.props.loading(true);
    if (this.props.isAuthenticated) {
      await backendApi.getColumnsData();
      await backendApi.getUserData();

      this.createInterval();
    } else {
      this.props.loading(false);
      if (this.interval) {
        clearInterval(this.interval);
      }
      return;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  createInterval() {
    this.interval = setInterval(() => {
      this.getData();
    }, 1000);
  }

  getData = () => {
    const clientList = JSON.parse(localStorage.getItem("clients"));
    const myData = JSON.parse(localStorage.getItem("myData"));
    this.setState({ email: myData.email, clientList: clientList });

    if (clientList !== null && myData !== null ) {
      clearInterval(this.interval);

      let isClient = false;
      let userRoles = {};
      let clientLimit = {};
      clientLimit[myData.client] = 1;

      myData.userRoles.forEach(async (item, i) => {
        userRoles[item.name] = 1;
        if (item.name === "Management") {
          this.props.admin(true);
        } else if (item.name === "QuickView") {
          isClient = true;
        }

      });

      this.props.setUserRoles(userRoles);
      this.props.setClientLimit(clientLimit);

      if (isClient) {
        this.props.setCustList([{name: myData.client}]);
        this.props.history("/records");
      } else {
        this.props.setCustList(clientList);
        this.props.loading(false);
      }
    }

  }

  saveDataToBackend = async () => {
    const client = this.state.client;
    const judgeItems = await this.getJudgeItems(client);
    console.log("ITEMS FOR :  " + client + " JUDGEMENTS: " + judgeItems.item.length);
    this.setState({ judgeItems: judgeItems.item, refLookup: judgeItems.refLookup });
    this.getSheriff();
  }

  getSheriff = async () => {
    const client = this.state.client;
    const sheriffItems = await this.getSheriffItems(client);
    console.log("ITEMS FOR :  " + client + " SHERIFF: " + sheriffItems.item.length);
    this.setState({ sheriffItems: sheriffItems.item });
    this.getSummons();
  }

  getSummons = async () => {
    const client = this.state.client;
    const summonsItems = await this.getSummonsItems(client);
    console.log("ITEMS FOR :  " + client + " SUMMONS: " + summonsItems.item.length);
    this.setState({ summonsItems: summonsItems.item });
    this.getOther();
  }

  getOther = async () => {
    const client = this.state.client;
    const otherItems = await this.getOtherItems(client);
    console.log("ITEMS FOR :  " + client + " OTHERS: " + otherItems.item.length);
    this.setState({ otherItems: otherItems.item });
    // this.updateCaseNumber(client);
    this.updateFilesandNotes(client);
  }

  updateCaseNumber = async (client) => {

    const iwmCase = await this.getItems(this.state.client);
    console.log("ITEMS: ", iwmCase.length);
    for (var caseInd = 0; caseInd < iwmCase.length; caseInd++) {
      console.log("PROCESSED: " + caseInd + " / " + iwmCase.length);
      let updateItem = iwmCase[caseInd];
      let caseNumber = updateItem.caseNumber;
      let refNumber = updateItem.refNumber;

      if (caseNumber === "") {
        for (var si = 0; si < this.state.summonsItems.length; si++) {;
          var sumRef = this.state.summonsItems[si].refNumber;
          if (refNumber === sumRef) {
            caseNumber = this.state.summonsItems[si].caseNumber;
            break;
          }
        }

        if (caseNumber === "") {
          for (var sh = 0; sh < this.state.sheriffItems.length; sh++) {;
            var shRef = this.state.sheriffItems[sh].refNumber;
            if (refNumber === shRef) {
              caseNumber = this.state.sheriffItems[sh].caseNumber;
              break;
            }
          }
        }

        if (caseNumber === "") {
          for (var ot = 0; ot < this.state.otherItems.length; ot++) {;
            var otRef = this.state.otherItems[ot].refNumber;
            if (refNumber === otRef) {
              caseNumber = this.state.otherItems[ot].caseNumber;
              break;
            }
          }
        }

        if (caseNumber !== "") {
          updateItem.caseNumber = caseNumber;
          await backendApi.updateItem({
            type: 'updateProcess',
            entry: updateItem
          },'item');
        }
      }

    }

  }

  updateFilesNotesSher = async () => {
    const client = this.state.client;
    const iwmCase = await this.getItems(client);
    console.log("ITEMS: ", iwmCase.length);

    for (var i = 0; i < iwmCase.length; i++) {
      console.log("PROCESSED: " + i + " / " + iwmCase.length);
      let updateItem = iwmCase[i];
      var saveFile = false;
      if (updateItem.summons[0].notes === "") {
        updateItem.summons[0].notes = [];
        saveFile = true;
      }

      if (updateItem.sheriff[0].notes === "") {
        updateItem.sheriff[0].notes = [];
        saveFile = true;
      }

      if (updateItem.judgement[0].notes === "") {
        updateItem.judgement[0].notes = [];
        saveFile = true;
      }

      if (updateItem.otherItem[0].notes === "") {
        updateItem.otherItem[0].notes = [];
        saveFile = true;
      }

      if (updateItem.summons[0].savedFiles === "") {
        updateItem.summons[0].savedFiles = [];
        saveFile = true;
      }

      if (saveFile) {
        await backendApi.updateItem({
          type: 'updateProcess',
          entry: updateItem
        },'item');
      }
    }

  }

  getJudgeItems(client) {
    return API.get('profast', '/listData/0,newTable,' + client + ",judge");
  }

  getSheriffItems(client) {
    return API.get('profast', '/listData/0,newTable,' + client + ",sheriff");
  }

  getSummonsItems(client) {
    return API.get('profast', '/listData/0,newTable,' + client + ",summons");
  }

  getOtherItems(client) {
    return API.get('profast', '/listData/0,newTable,' + client + ",other");
  }

  getItems(client) {
    return API.get('profast', '/listData/0,isJudge,' + client);
  }

  render() {

    return (
      <div className="Home">

        <NavTabs className="nav-tabs" rootProps={this.props.history} auth={this.props.roles}/>

        <img src={Background} alt="img" className="background-home-img"/>

        <div style={{ display: this.state.email === "profastadm@gmail.com" ? '' : 'none' }}>

          <Form.Control as="select" custom style={{marginTop: "15rem", width: "20rem"}}
            onChange={e => this.setState({ client: e.target.value })}>

            {this.state.clientList.map((client, index) => (
              <option key={index} >{client.name}</option>
            ))}

          </Form.Control>
          <Button style={{marginTop: "15rem"}} onClick={this.updateFilesNotesSher}>BOOM Sher</Button>

        </div>

      </div>
    );
  }
}
