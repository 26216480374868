import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Auth } from "aws-amplify";
import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      showError: false,
      errorMessage: ''
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    this.props.loading(true);
    event.preventDefault();
    this.setState({errorMessage: "", showError: false});
    try {
      let email = this.state.email.toLowerCase();
      const user = await Auth.signIn(email, this.state.password);

      if (user) {
        this.props.userHasAuthenticated(true);
        this.props.history("/home");
      }

    } catch (e) {
      this.props.loading(false);
      this.setState({errorMessage: e.message, showError: true});
    }
  }

  render() {
    return (
      <div className="Login">
        <Form onSubmit={this.handleSubmit}>
          <Form.Group size="lg" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={!this.validateForm()}>
            Login
          </Button>
          <Alert show={this.state.showError} variant='danger' >
            {this.state.errorMessage}
          </Alert>
        </Form>
      </div>
    );
  }
}
