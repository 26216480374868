import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast, Flip } from 'react-toastify';
import NavTabs from "../home/NavTabs";
import DatePicker from "react-datepicker";
import { FiPlusCircle, FiMinusCircle, FiSave } from "react-icons/fi";
import { FaUndo } from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import NotesModal from "../home/NotesModal";
import { XPathResult } from "xpath-react";
import { API } from 'aws-amplify';

import Barcode from './Barcode';

import "./New.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';

import * as backendApi from '../../services/api';
import * as struct from "./itemStructures";

export default class NewSummons extends Component {

  notifySave = () => toast.success('Summons Saved', {delay: 0, containerId: 'success', autoClose: 2000});
  notifyError = () => toast.error('Error during save', {delay: 0, containerId: 'error', autoClose: 2000});

  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      newSummons: [],
      failed: [],
      court: [],
      notesShow: false,
      noteText: "",
      notesTable: false,
      scannedCode: "nothing scanned",
      notes: [],
      existingRefs: {},
      disableSave: false,
      loadingRefs: true,
      clientsRef: {}
    };
  }

  async componentDidMount() {
    const clients = JSON.parse(localStorage.getItem('clients'));
    const courts = JSON.parse(localStorage.getItem('courts'));
    const process = JSON.parse(localStorage.getItem('process'));
    const plaintiffs = JSON.parse(localStorage.getItem('plaintiffs'));
    const sheriffs = JSON.parse(localStorage.getItem('sheriffs'));
    const columns = JSON.parse(localStorage.getItem('summonsColumns'));

    const existingItems = JSON.parse(localStorage.getItem('existingRefs')) === null ? {} : JSON.parse(localStorage.getItem('existingRefs'));
    const clientsRef = JSON.parse(localStorage.getItem('clientsRef')) === null ? {} : JSON.parse(localStorage.getItem('clientsRef'));

    this.setState({
      columns: columns,
      clients: clients,
      courts: courts,
      process: process,
      plaintiffs: plaintiffs,
      sheriffs: sheriffs,
      existingRefs: existingItems,
      clientsRef: clientsRef,
      loadingRefs: false
    });

  }

  addNewEntry = () => {
    let newEntries = this.state.newSummons;
    let newSumsStruct = struct.newSummons();
    newEntries.push(newSumsStruct);

    this.setState({ newSummons: newEntries });
  }

  updateClient = async (tableIndex, e) => {
    const client = e.target.value.toUpperCase();
    this.setState(state => state.newSummons[tableIndex].client = client);

    if (!(client in this.state.clientsRef)) {
      const clientSearch = await this.getAllClients(client);

      let clients = this.state.clientsRef;
      clients[client] = 1;
      const existingItems = JSON.parse(localStorage.getItem('existingRefs')) === null ? {} : JSON.parse(localStorage.getItem('existingRefs'));


      clientSearch.forEach((item, i) => {
        existingItems[item.refNumber] = 1;
      });

      this.setState({clientsRef: clients});
      this.setState({existingRefs: existingItems});

      localStorage.setItem("existingRefs", JSON.stringify(existingItems));
      localStorage.setItem("clientsRef", JSON.stringify(clients));
    }

    this.setState(state => state.newSummons[tableIndex].disabled = false);
  }

  getAllClients(client) {
    return API.get('profast', '/listData/0,clientSearch,' + client);
  }

  updateRefNumber = (tableIndex, e) => {
    const refNumber = e.target.value.toUpperCase();
    this.setState(state => state.newSummons[tableIndex].refNumber = refNumber);
  }

  showNotesTable = () => {
    this.setState({ notesTable: true });
  }

  setCurrentRow = (tableIndex) => {
    this.setState({ notesShow: true, notes: this.state.newSummons[tableIndex].notes, currentIndex: tableIndex });
  }

  getNoteText = (event) => {
    this.setState({ noteText: event.target.value })
  }

  onModalClose = () => {
    this.setState({ notesShow: false, notesTable: false})
  }

  addNewNote = () => {
    const index = this.state.currentIndex;
    const myData = JSON.parse(localStorage.getItem('myData'));
    let newEntries = this.state.newSummons;
    const noteText = this.state.noteText;

    newEntries[index].notes.push({
      createdOn: moment().format("DD/MM/YYYY"),
      createdBy: myData.email,
      note: noteText
    });

    this.setState({
      newSummons: newEntries,
      noteText: "",
      notesShow: false,
      notesTable: false
    });
  }

  makeId = (length) => {
    var result = [];
    var characters = '123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
  }

  genBarcode = async (index) => {

    let refNumber = this.state.newSummons[index].refNumber;
    if (refNumber.includes("/")) {
      refNumber = refNumber.replace(/\//g, 'slash');
    }
    if (refNumber === "") {
      alert("You need to fill in a reference number before generating a barcode");
    } else {
      const updateItems = await backendApi.getReference(refNumber);
      if (updateItems.length > 0) {
        const existingItems = JSON.parse(localStorage.getItem('existingRefs')) === null ? {} : JSON.parse(localStorage.getItem('existingRefs'));
        const existRef = updateItems[0].refNumber;
        existingItems[existRef] = 1;
        this.setState({existingRefs: existingItems});
      }

      const rand = this.makeId(12);
      setTimeout(() => {
        this.setState(state => state.newSummons[index].barcode = rand);
      }, 100);

      setTimeout(() => {
        this.onPrintBarcode(rand);
      }, 500);
    }

  }

  onPrintBarcode = (id) => {
    if (window.location.hostname !== "localhost") {
      var container = document.getElementById(id);
      var printWindow = window.open();
      printWindow.document.writeln(container.innerHTML);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  }

  onPrintAnotherBarcode = (oEvent) => {
    var container = oEvent.target.ownerSVGElement;
    var printWindow = window.open();
    printWindow.document.writeln(container.outerHTML);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }

  removeEntry = (index) => {
    let newEntries = this.state.newSummons;
    newEntries.splice(index, 1);
    this.setState({ newSummons: newEntries });
  }

  updateDate = (what, index, date) => {
    date = moment(date).format("DD/MM/YYYY");
    this.setState(state => state.newSummons[index][what] = date);
  }

  handleChangeRaw(what, index) {
    this.setState(state => state.newSummons[index][what] = "");
  }

  saveNewEntries = () => {
    const myData = JSON.parse(localStorage.getItem('myData'));
    let newEntries = this.state.newSummons;
    let failedEntries = [];
    let barcodeCheck = true;
    let refCheck = "";
    let count = 0;
    let existingRefs = this.state.existingRefs;

    newEntries.forEach((item, i) => {
      if (item.barcode === "Generate") {
        barcodeCheck = false;
      }

      if (item.refNumber === "") {
        refCheck = "blank";
      }

      if (item.refNumber in existingRefs) {
        refCheck = item.refNumber;
      }
    });

    if (!barcodeCheck) {
      alert("You need to generate barcodes by clicking 'Generate'");
      return;
    }

    if (refCheck === "blank") {
      alert("Please check you filled in all reference fields");
      return;
    } else if (refCheck !== "") {
      alert("Reference Number " + refCheck + " already exists.");
      return;
    }

    this.props.loading(true);

    newEntries.forEach(async (item, i) => {
      existingRefs[item.refNumber] = 1;

      item.createdBy = myData.email;
      item.createdOn = moment().format("DD/MM/YYYY");
      item.updatedBy = myData.email;
      item.updateDate = moment().format("DD/MM/YYYY");

      const barcode = item.barcode;
      let result = document.evaluate("//*[contains(text(), '" + barcode + "')]", document, null, XPathResult.ANY_UNORDERED_NODE_TYPE, null).singleNodeValue;
      result = result.innerHTML;

      let newSheriff = struct.newAddSheriff(item);
      let newJudgement = struct.newAddJudge(item, true);
      let newOther = struct.newAddOther(item);

      var newStruct = {
        barcode: result,
        refNumber: item.refNumber,
        batchNumber: item.batchNumber,
        caseNumber: item.caseNumber,
        client: item.client,
        isSummons: true,
        isSheriff: false,
        isJudge: false,
        isOther: false,
        summons: [item],
        sheriff: [newSheriff],
        judge: [newJudgement],
        otherItem: [newOther]
      };

      delete item.refNumber;
      delete item.barcode;
      delete item.batchNumber;
      delete item.client;
      // delete item.caseNumber;

      const response = await backendApi.createNewItem({
        type: 'newProcess',
        entry: newStruct
      });

      count++;

      if (response.status === 200) {
        this.notifySave();
      } else {
        failedEntries.push(item);
        this.notifyError();
      }

      this.props.loading(false);

      if (count === newEntries.length) {
        this.setState({ failed: failedEntries, newSummons: [], existingRefs: existingRefs });
      }

    });
  }

  render() {
    return (
      <div className="Summons">

        <ToastContainer enableMultiContainer containerId={'success'} transition={Flip} position={toast.POSITION.TOP_CENTER} />
        <ToastContainer enableMultiContainer containerId={'error'} transition={Flip} position={toast.POSITION.TOP_CENTER} />

        <NavTabs className="nav-tabs" rootProps={this.props.history} auth={this.props.roles}/>

        <NotesModal onSaveNote={this.addNewNote} showNotesTable={this.showNotesTable} notes={this.state.notes}
          getNoteText={this.getNoteText} onModalClose={this.onModalClose} state={this.state} />

        <div className="table-container">
          <div className="summons-heading">
            <div>
              <h3>NEW SUMMONS</h3>
              <Spinner className="" style={{display: this.state.loadingRefs ? "" : "none", marginLeft: "1.5rem" }}
                animation="border" variant="warning"/>
              <FiPlusCircle className="ml-3 mb-2" onClick={this.addNewEntry}
                style={{color: "#19A979", fontSize: "2rem", display: this.state.loadingRefs ? "none" : "" }} />
            </div>

            <FaUndo className="undo-new-button" style={{ textAlign: "center", display: this.state.newSummons.length > 0 || this.state.failed.length > 0 ? '' : 'none' }}
              onClick={() => this.setState({ newSummons: [], failed: [] })} />
            <Button className="save-newitem-button" disabled={this.state.disableSave} onClick={this.saveNewEntries}
              style={{ textAlign: "center", display: this.state.newSummons.length > 0 ? '' : 'none' }}>
              <FiSave className="save-newitem-button-icon"/>
            </Button>

          </div>

          <Table striped bordered hover size="sm" responsive="sm" className="summons-table">
            <thead>
              <tr>
                {this.state.columns.map((column, colIndex) => (
                  <th key={colIndex} style={{ display: column.active ? '' : 'none' }}>{column.name}</th>
                ))}
                <th style={{ textAlign: "center", display: this.state.newSummons.length > 0 ? '' : 'none' }}>Remove</th>
              </tr>
            </thead>

            <tbody>

              {this.state.newSummons.map((row, tableIndex) => (
                <tr key={tableIndex}>

                  <td>

                    <Form.Control as="select" custom value={row.client}
                      onChange={e => this.updateClient(tableIndex, e)}>

                      {this.state.clients.map((client, index) => (
                        <option key={index} >{client.name}</option>
                      ))}

                    </Form.Control>

                  </td>

                  <td>
                    <Form.Control as="select" custom value={row.process}
                      onChange={e => this.setState(state => state.newSummons[tableIndex].process = e.target.value)}>

                      {this.state.process.map((process, index) => (
                        <option key={index}>{process.name}</option>
                      ))}

                    </Form.Control>
                  </td>

                  <td>

                    <Form.Control as="select" custom value={row.court}
                      onChange={e => this.setState(state => state.newSummons[tableIndex].court = e.target.value)}>

                      {this.state.courts.map((court, index) => (
                        <option key={index}>{court.name}</option>
                      ))}

                    </Form.Control>

                  </td>

                  <td>

                    <Form.Control as="select" custom value={row.plaintiff}
                      onChange={e => this.setState(state => state.newSummons[tableIndex].plaintiff = e.target.value)}>

                      {this.state.plaintiffs.map((plaintiff, index) => (
                        <option key={index}>{plaintiff.name}</option>
                      ))}

                    </Form.Control>

                  </td>

                  <td>

                    <Form.Control id="batchNumber" type="text" value={row.batchNumber}
                      onChange={e => this.setState(state => state.newSummons[tableIndex].batchNumber = e.target.value)} />

                  </td>

                  <td>

                    <Form.Control id="refNumber" disabled={row.disabled} type="text" style={{boxShadow: row.refNumber in this.state.existingRefs ? "0 0 0 0.2rem red" : "" }}
                      value={row.refNumber} onChange={e => this.updateRefNumber(tableIndex, e)} />

                  </td>

                  <td>

                    <Form.Control id="caseNumber" type="text" value={row.caseNumber}
                      onChange={e => this.setState(state => state.newSummons[tableIndex].caseNumber = e.target.value)} />

                  </td>

                  <td>

                    <DatePicker id="issueDate" dateFormat="dd/MM/yyyy" value={row.issueDate}
                      onChange={date => this.updateDate("issueDate", tableIndex, date)}
                      onChangeRaw={(event) => this.handleChangeRaw("issueDate", tableIndex)}/>

                  </td>

                  <td>

                    <Form.Control as="select" custom value={row.sheriff}
                      onChange={e => this.setState(state => state.newSummons[tableIndex].sheriff = e.target.value)}>

                      {this.state.sheriffs.map((sheriff, index) => (
                        <option key={index}>{sheriff.name}</option>
                      ))}

                    </Form.Control>

                  </td>

                  <td>

                    <DatePicker id="toSheriff" dateFormat="dd/MM/yyyy" value={row.toSheriff}
                      onChange={date => this.updateDate("toSheriff", tableIndex, date)}
                      onChangeRaw={(event) => this.handleChangeRaw("toSheriff", tableIndex)}/>

                  </td>

                  <td>

                    <DatePicker id="toClient" dateFormat="dd/MM/yyyy" value={row.toClient}
                      onChange={date => this.updateDate("toClient", tableIndex, date)}
                      onChangeRaw={(event) => this.handleChangeRaw("toClient", tableIndex)}/>

                  </td>

                  <td>

                    <DatePicker id="shipToCourt" dateFormat="dd/MM/yyyy" value={row.shipToCourt}
                      onChange={date => this.updateDate("shipToCourt", tableIndex, date)}
                      onChangeRaw={(event) => this.handleChangeRaw("shipToCourt", tableIndex)}/>

                  </td>

                  <td>

                    <DatePicker id="shipFromCourt" dateFormat="dd/MM/yyyy" value={row.shipFromCourt}
                      onChange={date => this.updateDate("shipFromCourt", tableIndex, date)}
                      onChangeRaw={(event) => this.handleChangeRaw("shipFromCourt", tableIndex)}/>

                  </td>

                  <td style={{textAlign: "center"}}>

                    <Button id={tableIndex} variant="light" onClick={() => this.setCurrentRow(tableIndex)}>
                      Notes ({row.notes.length})
                    </Button>

                  </td>

                  <td style={{textAlign: "center"}}>

                    {this.state.newSummons[tableIndex].barcode === "Generate" ?
                      <Button variant="link" onClick={() => this.genBarcode(tableIndex)}>{this.state.newSummons[tableIndex].barcode}</Button> :
                      <Button variant="link" className="barcode-button-new" onClick={this.onPrintAnotherBarcode}><Barcode barId={row.barcode}/></Button>

                    }

                  </td>

                  <td style={{textAlign: "center", color: "#dc0d0e", fontSize: "1.5rem"}}>

                    <FiMinusCircle style={{fontSize: "1.5rem", cursor: "pointer"}} className="mt-1" onClick={event => this.removeEntry(tableIndex)}/>

                  </td>

                </tr>
              ))}

              {this.state.failed.map((row, tableIndex) => (
                <tr key={tableIndex}>

                  <td>{row.client}</td>

                  <td>{row.process}</td>

                  <td>{row.court}</td>

                  <td>{row.plaintiff}</td>

                  <td>{row.batchNumber}</td>

                  <td>{row.refNumber}</td>

                  <td>{row.caseNumber}</td>

                  <td>{row.issueDate}</td>

                  <td>{row.sheriff}</td>

                  <td>{row.toSheriff}</td>

                  <td>{row.toClient}</td>

                  <td>{row.shipToCourt}</td>

                  <td>{row.shipFromCourt}</td>

                  <td></td>

                  <td></td>

                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
