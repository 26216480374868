import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import { MdPowerSettingsNew } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import "./App.css";
import Logo from "./containers/img/scales.png";
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import LoadingScales from "./containers/img/loading.gif";

import * as backendApi from './services/api';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      loading: false,
      searchLoading: false,
      isAdmin: false,
      showHeadSearch: false,
      userRoles: {},
      clientLimit: {},
      searchVal: "",
      searchList: [],
      customerList: [],
      customerSelected: "ALL",
      itemSelected: "",
      notFound: ""
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
      this.history("/home");

    } catch(e) {
      this.userHasAuthenticated(false);
      this.props.history.push("/login");
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  isLoading = loading => {
    this.setState({ loading: loading });
  }

  isAdmin = admin => {
    this.setState({ isAdmin: admin });
  }

  setUserRoles = roles => {
    this.setState({ userRoles: roles }, () => {
      this.setState({ showHeadSearch: true });
    });
  }

  setClientLimit = clients => {
    this.setState({ clientLimit: clients });
  }

  history = path => {
    this.props.history.push(path)
  }

  setCustList = (list) => {

    if (list[0].name === "") {
      list[0].name = "ALL";
    }

    this.setState({ customerList: list });

    if (list.length === 1) {
      this.setState({ customerSelected: list[0].name });
    }
  }

  onSearchInput = async (event) => {
    event.preventDefault();
    let searchVal = this.state.searchVal;

    if (searchVal.length > 2) {
      this.setState({ searchLoading: true, notFound: "" });

      if (this.state.searchList.length > 0) {
        let newList = [];
        this.state.searchList.forEach((item, i) => {
          if (item.caseNumber.includes(searchVal) || item.refNumber.includes(searchVal)) {
            newList.push(item);
          }
        });

        this.setState({ searchList: newList, searchLoading: false });

      } else {
        if (searchVal.includes("/")) {
          searchVal = searchVal.replace(/\//g, 'slash');
        }
        let searchReturned = [];
        try {
          searchReturned = await backendApi.getSearchRefCaseList(this.state.customerSelected + "," + searchVal);
        } catch (e) {
          console.log("Error fetching data. Timeout");
          searchReturned = await backendApi.getReference(searchVal);
        }

        if (searchReturned.length > 0) {
          this.setState({ searchList: searchReturned, searchLoading: false });
        } else {
          this.setState({ notFound: "Not Found", searchLoading: false });
        }
      }


    } else {
      this.setState({ searchList: [], itemSelected: [], notFound: "" });
    }

  }

  setSearchVal = (searchVal) => {
    this.setState({searchVal: searchVal.toUpperCase()});

    if (searchVal.length === 0) {
      this.setState({ searchList: [], itemSelected: [], notFound: "" });
    }
  }

  onSearchItemSelect = async (itemClicked) => {

    if (itemClicked.summons[0].notes === "") {
      itemClicked.summons[0].notes = [];
    }

    if (itemClicked.judgement[0].notes === "") {
      itemClicked.judgement[0].notes = [];
    }

    if (itemClicked.sheriff[0].notes === "") {
      itemClicked.sheriff[0].notes = [];
    }

    if (itemClicked.otherItem[0].notes === "") {
      itemClicked.otherItem[0].notes = [];
    }

    if (itemClicked.summons[0].savedFiles === "") {
      itemClicked.summons[0].savedFiles = [];
    }

    this.setState({ itemSelected: itemClicked, searchList: [], searchVal: "" });
    this.history("/itm");
    setTimeout(() => {
      this.history("/item");
    }, 1000);
  }

  onCustomerSelect = (event) => {
    console.log(event.target.text);
    this.setState({ customerSelected: event.target.text });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    localStorage.clear();
    window.location.reload();
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      loading: this.isLoading,
      isLoading: this.state.loading,
      admin: this.isAdmin,
      isAdmin: this.state.isAdmin,
      setUserRoles: this.setUserRoles,
      roles: this.state.userRoles,
      setClientLimit: this.setClientLimit,
      clientLimit: this.state.clientLimit,
      history: this.history,
      itemSelected: this.state.itemSelected,
      setCustList: this.setCustList
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App">

        <div className="loading-spinner" style={{display: this.state.loading ? "": "none", fontSize: "1.3rem"}}>
          <img src={LoadingScales} alt="img" />
        </div>

        <Navbar fixed="top" className="bg-dark justify-content-between" variant="dark">
          <div clssname="header">
            <img src={Logo} alt="img" height="50" style={{position: "absolute"}}/>
            <Navbar.Brand href="/" style={{fontSize: "1.7rem", marginLeft: "6rem"}}>ProFast SA</Navbar.Brand>
          </div>

          <Nav>
            {this.state.isAuthenticated
              ?

              <Fragment>

                <InputGroup style={{ display: this.state.showHeadSearch ? '' : 'none' }} className="m-1">
                  <DropdownButton as={InputGroup.Prepend} variant="outline-light" title={this.state.customerSelected} >
                    <Dropdown.Item style={{display: this.state.customerList > 1 ? "" : "none"}}
                      onClick={e => this.onCustomerSelect(e)}>ALL</Dropdown.Item>
                    <Dropdown.Divider style={{display: this.state.customerList > 1 ? "" : "none"}} />

                    {this.state.customerList.map((row, index) => (
                      <Dropdown.Item key={index} onClick={e => this.onCustomerSelect(e)}>{row.name}</Dropdown.Item>
                    ))}

                  </DropdownButton>

                  <form onSubmit={this.onSearchInput}>
                    <Form.Control id="searchInput" type="text" placeholder="Search for Ref number"
                      className="mr-lg-5 search-input" onChange={e => this.setSearchVal(e.target.value)} value={this.state.searchVal} />
                  </form>


                  <Spinner className="search-loading" style={{display: this.state.searchLoading ? "" : "none" }}
                    animation="border" variant="warning"/>

                  <FiSearch className="search-icon" onClick={this.onSearchInput}
                    style={{display: this.state.searchLoading || this.state.notFound !== '' ? "none" : "" }}/>

                  <Badge variant="warning" className="item-not-found-search"
                    style={{ fontSize: "1rem", display: this.state.notFound !== '' ? '' : 'none' }}>
                    Not Found
                  </Badge>

                  <ListGroup className="search-dropdown" style={{ display: this.state.searchList.length > 0 ? '' : 'none' }}>
                    {this.state.searchList.map((item, si) => (
                      <ListGroup.Item key={si} onClick={() => this.onSearchItemSelect(item)}>
                        <div style={{display: "grid"}}>
                          <span>Reference: {item.refNumber}</span>
                          <span>Case: {item.caseNumber}</span>
                          <span>Client: {item.client}</span>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                </InputGroup>

                <Nav.Item onClick={this.handleLogout}><MdPowerSettingsNew className="nav-color h3 mt-2 mr-2" /></Nav.Item>

              </Fragment>

              : ""
            }
          </Nav>
        </Navbar>

        <Routes childProps={childProps} />
      </div>
    );
  }

}


export default withRouter(App);
