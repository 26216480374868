import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const NotesModal = (props) => (
  <div>
    <Modal show={props.state.notesShow} onHide={props.onModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create a Note</Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <Form.Label>New Note</Form.Label>
        <Form.Control id="note" type="text" placeholder="type note here..." onChange={props.getNoteText} />

        <Form.Label className="mt-3" style={{ display: props.state.notesTable ? '' : 'none' }}>Existing Notes</Form.Label>
          <Table striped bordered size="sm" responsive="sm" style={{ display: props.state.notesTable ? '' : 'none' }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>User</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>

              {props.notes.map((note, index) => (
                <tr key={index}>

                  <td>{note.createdOn}</td>
                  <td>{note.createdBy}</td>
                  <td>{note.note}</td>

                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={props.showNotesTable}>
            Current Notes
          </Button>
          <Button variant="primary" onClick={props.onSaveNote}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
);
export default NotesModal;
