import React, { Component } from 'react';
import { FiSave, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FaUndo } from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { ToastContainer, toast, Flip } from 'react-toastify';
import NavTabs from "../home/NavTabs";
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import FileBase64 from 'react-file-base64';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import "./ItemView.css";

import Barcode from './Barcode';

import SummonsTable from "./SummonsTable";
import SheriffTable from "./SheriffTable";
import JudgementTable from "./JudgementTable";
import OtherTable from "./OtherTable";
import PdfViewer from "./PdfViewer";
import { s3Upload } from "../../libs/awsLib";
import { Storage } from "aws-amplify";
import * as backendApi from '../../services/api';

export default class ItemView extends Component {

  notifySave = () => toast.success('Saved', {
    delay: 0,
    containerId: 'success',
    autoClose: 2000
  });
  notifyFileRemoved = () => toast.success('File(s) Removed', {
    delay: 0,
    containerId: 'success',
    autoClose: 2000
  });
  notifyError = () => toast.error('Error during save', {
    delay: 0,
    containerId: 'error',
    autoClose: 2000
  });
  indexStart;

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      base64: {
        index: 0,
        file: null
      },
      pdfFiletype: "",
      pdfSaveButton: true,
      pdfSaveLoading: false,
      pdfSaved: false,
      pdfSaveError: false,
      pdfDateShipped: null,
      pdfsSelected: [],
      itemSelected: this.props.itemSelected,
      summonsColumns: [],
      changed: [],
      changeLookup: {},
      summonsChanged: false,
      sheriffChanged: false,
      judgeChanged: false,
      otherChanged: false,
      showSummons: false,
      refChanged: ""
    };
  }

  componentDidMount() {

    const myData = JSON.parse(localStorage.getItem('myData'));
    const clients = JSON.parse(localStorage.getItem('clients'));
    const courts = JSON.parse(localStorage.getItem('courts'));
    const process = JSON.parse(localStorage.getItem('process'));
    const plaintiffs = JSON.parse(localStorage.getItem('plaintiffs'));
    const sheriffs = JSON.parse(localStorage.getItem('sheriffs'));
    const status = JSON.parse(localStorage.getItem('status'));
    const summonsColumns = JSON.parse(localStorage.getItem('summonsColumns'));
    const sheriffColumns = JSON.parse(localStorage.getItem('sheriffColumns'));
    const judgementColumns = JSON.parse(localStorage.getItem('judgeColumns'));
    const otherColumns = JSON.parse(localStorage.getItem('otherColumns'));

    if (myData.client === "SMIT JONES AND PRATT") {
      clients.push({ name: "SMIT JONED & PRATT" });
    } else if (myData.client === "CONNACK MULLER") {
      clients.push({ name: "CONNACK" });
    } else if (myData.client === "RAMSUJOO DU PLESSIS") {
      clients.push({ name: "RAMSUJOO" });
    }

    summonsColumns.forEach((col, i) => {
      switch (col.name) {
        case "Date Created":
          col.active = false;
          break;
        case "Notes":
          col.active = false;
          break;
        case "Barcode":
          col.active = false;
          break;
        default:
          col.active = true;
      }
    });

    sheriffColumns.forEach((col, i) => {
      switch (col.name) {
        case "Date Created":
          col.active = false;
          break;
        case "Notes":
          col.active = false;
          break;
        case "Barcode":
          col.active = false;
          break;
        default:
          col.active = true;
      }
    });

    judgementColumns.forEach((col, i) => {
      switch (col.name) {
        case "Date Created":
          col.active = false;
          break;
        case "Notes":
          col.active = false;
          break;
        case "Barcode":
          col.active = false;
          break;
        default:
          col.active = true;
      }
    });

    otherColumns.forEach((col, i) => {
      switch (col.name) {
        case "Date Created":
          col.active = false;
          break;
        case "Notes":
          col.active = false;
          break;
        case "Barcode":
          col.active = false;
          break;
        case "Court":
        case "Status Date":
        case "Batch":
          if (this.state.itemSelected.client === "THIPA INCORPORATED") {
            col.active = false;
          }
          break;
        default:
          col.active = true;
      }

    });

    let processFilter = process.filter((a, i) => {
      if (a.name === "") {
        a.isJudgement = true;
      }
      return a.isJudgement;
    });

    this.setState({
      summonsColumns: summonsColumns,
      sheriffColumns: sheriffColumns,
      judgementColumns: judgementColumns,
      otherColumns: otherColumns,
      clients: clients,
      courts: courts,
      process: process,
      judgeProcess: processFilter,
      plaintiffs: plaintiffs,
      sheriffs: sheriffs,
      status: status
    });

    this.getData();
  }

  getData = () => {

    let readOnlySetting = true;
    if ("FileUpdate" in this.props.roles) {
      readOnlySetting = false;
    }

    this.setState({ showSummons: true, readOnly: readOnlySetting });

    //FIX FOR CASE NUMBER ON ITEM LEVEL - MOVE FROM HEADER TO ITEM

    if (!this.state.itemSelected.summons[0].caseNumber) {
      this.setState(state => state.itemSelected.summons[0].caseNumber = this.state.itemSelected.caseNumber);
    }
    if (!this.state.itemSelected.sheriff[0].caseNumber) {
      this.setState(state => state.itemSelected.sheriff[0].caseNumber = this.state.itemSelected.caseNumber);
    }
    if (!this.state.itemSelected.judgement[0].caseNumber) {
      this.setState(state => state.itemSelected.judgement[0].caseNumber = this.state.itemSelected.caseNumber);
    }
    if (!this.state.itemSelected.otherItem[0].caseNumber) {
      this.setState(state => state.itemSelected.otherItem[0].caseNumber = this.state.itemSelected.caseNumber);
    }
  }

  showSelectedTab = (e) => {
    switch (e) {
      case "summons":
        this.setState({ showSummons: true, showSheriff: false, showJudge: false, showOther: false });
        break;
      case "sheriff":
        this.setState({ showSheriff: true, showSummons: false, showJudge: false, showOther: false });
        break;
      case "judgement":
        this.setState({ showJudge: true, showSummons: false, showSheriff: false, showOther: false });
        break;
      case "other":
        this.setState({ showOther: true, showSummons: false, showSheriff: false, showJudge: false });
        break;
      default:

    }
  }

  onPrintBarcode = () => {
    var container = document.getElementById("div-svg");
    var printWindow = window.open();
    printWindow.document.writeln(container.innerHTML);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }

  getFiles(files) {
    this.setState({
      files: files,
      base64: {
        index: 0,
        file: files[0].base64
      }
    });
  }

  openPdfModal = () => {
    this.setState({ showUploadPDFModel: true });

    let day = moment().day();
    let dateWednesday = null;
    if (day > 3) {
      dateWednesday = 10 - day;
    } else {
      dateWednesday = 3 - day;
    }
    dateWednesday = moment().add(dateWednesday, "days");

    this.setState({ pdfDateShipped: moment(dateWednesday).format("DD/MM/YYYY") });
  }

  onPdfRadios = (radio) => {

    switch (radio) {
      case "Issued Summons":
        this.setState({
          pdfFiletype: "IS",
          is: true,
          sd: false,
          sr: false,
          pl: false,
          gq: false,
          ot: false,
          pdfSaveButton: false
        });
        break;
      case "Sheriff Delivery":
        this.setState({
          pdfFiletype: "SD",
          is: false,
          sd: true,
          sr: false,
          pl: false,
          gq: false,
          ot: false,
          pdfSaveButton: false
        });
        break;
      case "Sheriff Return":
        this.setState({
          pdfFiletype: "SR",
          is: false,
          sd: false,
          sr: true,
          pl: false,
          gq: false,
          ot: false,
          pdfSaveButton: false
        });
        break;
      case "Proof of Lodge":
        this.setState({
          pdfFiletype: "PL",
          is: false,
          sd: false,
          sr: false,
          pl: true,
          gq: false,
          ot: false,
          pdfSaveButton: false
        });
        break;
      case "Granted Queued":
        this.setState({
          pdfFiletype: "GQ",
          is: false,
          sd: false,
          sr: false,
          pl: false,
          gq: true,
          ot: false,
          pdfSaveButton: false
        });
        break;
      case "Other":
        this.setState({
          pdfFiletype: "OT",
          is: false,
          sd: false,
          sr: false,
          pl: false,
          gq: false,
          ot: true,
          pdfSaveButton: false
        });
        break;
      default:
        this.setState({
          pdfFiletype: "",
          is: false,
          sd: false,
          sr: false,
          pl: false,
          gq: false,
          pdfSaveButton: true
        });
    }
  }

  viewNextFile = (where) => {
    let ind = this.state.base64.index;
    let files = this.state.files;

    if (files.length > 1) {
      if (where === "next") {
        ind++;
        const fileNext = files[ind];

        if (fileNext) {
          this.setState({
            base64: {
              index: ind,
              file: files[ind].base64
            }
          });
        } else {
          ind = files.length - 1;
          this.setState({
            base64: {
              index: ind,
              file: files[ind].base64
            }
          });
        }

      } else {
        ind--;
        if (ind >= 0) {
          this.setState({
            base64: {
              index: ind,
              file: files[ind].base64
            }
          });
        } else {
          this.setState({
            base64: {
              index: 0,
              file: files[0].base64
            }
          });
        }

      }
    }

  }

  onSaveAllPdfs = () => {
    this.setState({ pdfSaveLoading: true });
    let summonsItem = this.state.itemSelected.summons[0];
    if (summonsItem.savedFiles === "") {
      summonsItem.savedFiles = [];
    }
    const filesLength = summonsItem.savedFiles.length;
    if (filesLength > 0) {
      this.indexStart = summonsItem.savedFiles[filesLength - 1].index + 1;
    } else {
      this.indexStart = filesLength + 1;
    }

    if (this.state.files.length > 0) {
      for (var i = 0; i < this.state.files.length; i++) {

        ((ind, that) => {

          let parent = that;

          setTimeout(() => {
            let file = parent.state.files[ind];
            file.index = this.indexStart;
            that.saveFile(file, summonsItem);
            this.indexStart++;
          }, 1000 * ind);
        })(i, this);

      }
    } else {
      this.setState({ pdfSaveLoading: false });
    }

  }

  saveFile = async (file, summonsItem) => {
    const dateShip = this.state.pdfDateShipped.replace(/\//g, "");
    file.idNumber = this.state.itemSelected.barcode;

    if (this.state.pdfFiletype === "IS") {
      file.name = this.state.pdfFiletype + "-" + this.state.itemSelected.refNumber + "-" + file.index;
    } else {
      file.name = this.state.pdfFiletype + "-" + this.state.itemSelected.refNumber + "-" + dateShip + "-" + file.index;
    }
    file.dateShipped = dateShip;

    if (file.file && file.file.size > 5000000) {
      alert(`Please pick a file smaller than ${5000000 / 1000000} MB.`);
      return;
    }

    try {

      const attachmentObj = {
        date: moment().format("DD/MM/YYYY"),
        id: file.idNumber,
        name: file.name,
        type: file.type,
        process: this.state.pdfFiletype,
        dateShipped: this.state.pdfFiletype === "IS"
          ? ""
          : file.dateShipped,
        key: file.name,
        index: file.index
      }

      summonsItem.savedFiles.push(attachmentObj);

      let response = await backendApi.updateItem({
        type: 'updateProcess',
        entry: this.state.itemSelected
      }, "summons");

      await s3Upload(file);

      if (response.status === 200) {
        this.setState({
          files: [],
          base64: {
            index: 0,
            file: file.base64
          },
          pdfSaveLoading: false,
          pdfSaved: true,
          pdfSaveError: false
        });
      } else {
        this.setState({
          files: [],
          base64: {
            index: 0,
            file: file.base64
          },
          pdfSaveLoading: false,
          pdfSaved: false,
          pdfSaveError: true
        });
      }

    } catch (e) {
      console.log(e);
    }
  }

  onPDFDialogClose = () => {
    this.setState({
      files: [],
      base64: {
        index: 0,
        file: null
      },
      pdfSaveLoading: false,
      pdfSaved: false,
      pdfSaveError: false,
      showUploadPDFModel: false
    });

    this.onPdfRadios(false);
  }

  showPDFs = async (file) => {
    // let fileUrl = await Storage.get(file.key);

    // const key = file.key;
    // const newKey = file.key.replace(/\//g, ".");
    // const newUrl = fileUrl.split(key);
    // fileUrl = newUrl[0] + newKey + newUrl[1];

    // window.open(fileUrl);
    try {
      let fileUrl = await Storage.get(file.key, { level: 'public' });
      window.open(fileUrl);
    } catch (error) {
      console.error("Error fetching file from S3:", error);
    }
  }

  addToDelete = (e, itemClicked) => {
    let checked = e.target.checked;
    const key = itemClicked.key;
    let pdfsSelected = this.state.pdfsSelected;

    if (checked) {
      pdfsSelected.push(key);
    } else {
      for (var i = 0; i < pdfsSelected.length; i++) {
        const keyVal = pdfsSelected[i];

        if (key === keyVal) {
          pdfsSelected.splice(i, 1);
          i--;
        }
      }
    }

    this.setState({ pdfsSelected: pdfsSelected });
  }

  deleteSelectedPDFs = async () => {
    const itemsToDelete = this.state.pdfsSelected;
    let itemSelected = this.state.itemSelected;
    let summonsItem = itemSelected.summons[0];

    for (var k = 0; k < itemsToDelete.length; k++) {
      let item = itemsToDelete[k];

      for (var i = 0; i < summonsItem.savedFiles.length; i++) {
        let fileId = summonsItem.savedFiles[i].name;

        if (fileId === item) {
          summonsItem.savedFiles.splice(i, 1);
          i--;
        }
      }
    }

    itemsToDelete.forEach(async (item, i) => {
      await Storage.remove(item);
    });

    let response = await backendApi.updateItem({
      type: 'updateProcess',
      entry: itemSelected
    }, "summons");

    if (response.status === 200) {
      this.notifyFileRemoved();
      this.setState({ itemSelected: itemSelected, pdfsSelected: [] });

      setTimeout(() => {
        summonsItem.savedFiles.forEach((item, i) => {
          let elName = item.name;

          if (document.getElementById(elName)) {
            document.getElementById(elName).checked = false;
          }

        });
      }, 2000);

    } else {
      this.notifyError();
    }

  }

  removeDate = (where, what) => {
    if (where === "summons") {
      this.setState(state => state.itemSelected.summons[0][what] = "");
    } else if (where === "sheriff") {
      this.setState(state => state.itemSelected.sheriff[0][what] = "");
    } else if (where === "judgement") {
      this.setState(state => state.itemSelected.judgement[0][what] = "");
    } else if (where === "other") {
      this.setState(state => state.itemSelected.otherItem[0][what] = "");
    }

  }

  onChanged = (table, field, value, noteIndex) => {
    const myData = JSON.parse(localStorage.getItem('myData'));
    let changed = this.state.changed;
    let lookup = this.state.changeLookup;
    let itemChange = "";
    const lookupCheck = field + table;

    if (table === "summons") {
      itemChange = JSON.stringify(this.state.itemSelected.summons[0]);
    } else if (table === "sheriff") {
      itemChange = JSON.stringify(this.state.itemSelected.sheriff[0]);
    } else if (table === "judgement") {
      itemChange = JSON.stringify(this.state.itemSelected.judgement[0]);
    } else if (table === "other") {
      itemChange = JSON.stringify(this.state.itemSelected.otherItem[0]);
    }

    if (noteIndex !== undefined) {
      let itemChanged = "";

      if (table === "summons") {
        itemChanged = this.state.itemSelected.summons[0];
      } else if (table === "sheriff") {
        itemChanged = this.state.itemSelected.sheriff[0];
      } else if (table === "judgement") {
        itemChanged = this.state.itemSelected.judgement[0];
      } else if (table === "other") {
        itemChanged = this.state.itemSelected.otherItem[0];
      }

      itemChanged.notes[noteIndex].new = false;

      setTimeout(() => {
        this.saveUpdates();
      }, 2000);

    } else {
      if (!(lookupCheck in lookup)) {
        const valueBefore = JSON.parse(itemChange);
        changed.push({ field: field, value: valueBefore[field], table: table });
        lookup[lookupCheck] = 1;
        this.setState({ changed: changed, changeLookup: lookup });
      }

      const updateDate = moment().format("DD/MM/YYYY");
      if (field === "refNumber" || field === "client" || field === "batchNumber") {
        if (field === "refNumber") {
          this.setState({ refChanged: this.state.itemSelected.refNumber });
        }
        this.setState(state => state.itemSelected[field] = value);

      } else {
        switch (table) {
          case "summons":
            this.setState({ summonsChanged: true });
            this.setState(state => state.itemSelected.summons[0][field] = value);
            this.setState(state => state.itemSelected.summons[0]["updateDate"] = updateDate);
            this.setState(state => state.itemSelected.summons[0]["updatedBy"] = myData.email);

            if (field === "sheriff") {
              this.setState(state => state.itemSelected.sheriff[0][field] = value);
            }

            if (field === "court" || field === "plaintiff") {
              this.setState(state => state.itemSelected.judgement[0][field] = value);
            }

            break;
          case "sheriff":
            this.setState({ sheriffChanged: true });
            this.setState(state => state.itemSelected.sheriff[0][field] = value);
            this.setState(state => state.itemSelected.sheriff[0]["updateDate"] = updateDate);
            this.setState(state => state.itemSelected.sheriff[0]["updatedBy"] = myData.email);

            break;
          case "judgement":
            this.setState({ judgeChanged: true });
            this.setState(state => state.itemSelected.judgement[0][field] = value);
            this.setState(state => state.itemSelected.judgement[0]["updateDate"] = updateDate);
            this.setState(state => state.itemSelected.judgement[0]["updatedBy"] = myData.email);

            break;
          case "other":
            this.setState({ otherChanged: true });
            this.setState(state => state.itemSelected.otherItem[0][field] = value);
            this.setState(state => state.itemSelected.otherItem[0]["updateDate"] = updateDate);
            this.setState(state => state.itemSelected.otherItem[0]["updatedBy"] = myData.email);

            break;
          default:
        }
      }
    }
  }

  revertChanges = () => {
    this.setState({ refChanged: "" });
    this.state.changed.forEach((item, i) => {

      switch (item.table) {
        case "summons":
          this.setState({ summonsChanged: false });
          this.setState(state => state.itemSelected.summons[0][item.field] = item.value);

          try {
            this.setState(state => state.itemSelected.sheriff[0][item.field] = item.value);
          } catch (e) { };
          try {
            this.setState(state => state.itemSelected.judgement[0][item.field] = item.value);
          } catch (e) { };
          break;
        case "sheriff":
          this.setState({ sheriffChanged: false });
          this.setState(state => state.itemSelected.sheriff[0][item.field] = item.value);
          try {
            this.setState(state => state.itemSelected.judgement[0][item.field] = item.value);
          } catch (e) { };
          break;
        case "judgement":
          this.setState({ judgeChanged: false });
          this.setState(state => state.itemSelected.judgement[0][item.field] = item.value);
          try {
            this.setState(state => state.itemSelected.other[0][item.field] = item.value);
          } catch (e) { };
          break;
        case "other":
          this.setState({ otherChanged: false });
          this.setState(state => state.itemSelected.other[0][item.field] = item.value);
          break;
        default:
      }
    });

    this.setState({ changed: [], changeLookup: {} });
  }

  addNewNote = (whereToAdd) => {

    const myData = JSON.parse(localStorage.getItem('myData'));

    switch (whereToAdd) {
      case "summons":

        let summonsNotes = this.state.itemSelected.summons[0].notes;
        summonsNotes.unshift({ createdOn: moment().format("DD/MM/YYYY"), createdBy: myData.email, note: "", new: true });

        this.setState(state => state.itemSelected.summons[0].notes = summonsNotes);

        break;

      case "sheriff":

        let sheriffNotes = this.state.itemSelected.sheriff[0].notes;
        sheriffNotes.unshift({ createdOn: moment().format("DD/MM/YYYY"), createdBy: myData.email, note: "", new: true });

        this.setState(state => state.itemSelected.sheriff[0].notes = sheriffNotes);

        break;

      case "judgement":

        let judgementNotes = this.state.itemSelected.judgement[0].notes;
        judgementNotes.unshift({ createdOn: moment().format("DD/MM/YYYY"), createdBy: myData.email, note: "", new: true });

        this.setState(state => state.itemSelected.judgement[0].notes = judgementNotes);

        break;

      case "other":

        let otherNotes = this.state.itemSelected.otherItem[0].notes;
        otherNotes.unshift({ createdOn: moment().format("DD/MM/YYYY"), createdBy: myData.email, note: "", new: true });

        this.setState(state => state.itemSelected.otherItem[0].notes = otherNotes);

        break;
      default:
    }
  }

  saveUpdates = async () => {
    const myData = JSON.parse(localStorage.getItem('myData'));
    this.props.loading(true);
    let response = null;
    let updateItem = this.state.itemSelected;

    if (this.state.summonsChanged) {
      updateItem.isSummons = true;
      updateItem.caseNumber = updateItem.summons[0].caseNumber;
    }

    if (this.state.sheriffChanged) {
      updateItem.isSheriff = true;
      updateItem.caseNumber = updateItem.sheriff[0].caseNumber;
      if (updateItem.sheriff[0].createdOn === "" || updateItem.sheriff[0].createdOn === null) {
        updateItem.sheriff[0].createdBy = myData.email;
        updateItem.sheriff[0].createdOn = moment().format("DD/MM/YYYY");
      }
    }

    if (this.state.judgeChanged) {
      updateItem.isJudge = true;
      updateItem.caseNumber = updateItem.judgement[0].caseNumber;
      if (updateItem.judgement[0].createdOn === "" || updateItem.judgement[0].createdOn === null) {
        updateItem.judgement[0].createdBy = myData.email;
        updateItem.judgement[0].createdOn = moment().format("DD/MM/YYYY");
      }

    }

    if (this.state.otherChanged) {
      updateItem.isOther = true;
      updateItem.caseNumber = updateItem.otherItem[0].caseNumber;
      if (updateItem.otherItem[0].createdOn === "" || updateItem.otherItem[0].createdOn === null) {
        updateItem.otherItem[0].createdBy = myData.email;
        updateItem.otherItem[0].createdOn = moment().format("DD/MM/YYYY");
      }

    }

    try {
      response = await backendApi.updateItem({
        type: 'updateProcess',
        entry: updateItem
      }, 'item');

      this.props.loading(false);

      if (response.status === 200) {

        if (this.state.refChanged !== "") {
          this.onDeleteRecord(true);
        } else {
          this.notifySave();

          this.setState({
            changed: [],
            changeLookup: {},
            summonsChanged: false,
            sheriffChanged: false,
            judgeChanged: false,
            otherChanged: false
          });
        }

      } else {
        this.notifyError();
      }
    } catch (e) {
      this.props.loading(false);
      this.notifyError();
    }
  }

  onDeleteRecord = async (refChanged) => {
    if (refChanged === true) {
      const deleteOld = await backendApi.deleteItem(this.state.refChanged, this.state.itemSelected.barcode);

      if (deleteOld.status === 200) {
        this.notifySave();

      } else {
        this.notifyError();
      }

    } else {
      const response = await backendApi.deleteItem(this.state.itemSelected.refNumber, this.state.itemSelected.barcode);

      if (response.status === 200) {
        this.notifySave();
        setTimeout(() => {
          this.props.history("/home");
        }, 2000);

      } else {
        this.notifyError();
      }
    }
  }

  render() {

    return (<div className="Item">

      <ToastContainer enableMultiContainer="enableMultiContainer" containerId={'success'} transition={Flip} position={toast.POSITION.TOP_CENTER} />
      <ToastContainer enableMultiContainer="enableMultiContainer" containerId={'error'} transition={Flip} position={toast.POSITION.TOP_CENTER} />

      <NavTabs className="nav-tabs" rootProps={this.props.history} auth={this.props.roles} /> {
        this.state.showUploadPDFModel
          ? this.showPDFModal()
          : ""
      }

      <Modal centered="centered" show={this.state.showDeleteModel}>

        <Modal.Header closeButton="closeButton">
          <Modal.Title >
            Delete Record!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete this record?</h5>
          <br />
          <p>Client: {this.state.itemSelected.client}</p>
          <p>Reference Number: {this.state.itemSelected.refNumber}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={e => this.setState({ showDeleteModel: false })}>Cancel</Button>
          <Button variant="danger" onClick={this.onDeleteRecord}>Delete</Button>
        </Modal.Footer>

      </Modal>

      <div className="item-container">

        <div className="item-heading">

          <Button className="upload-pdf-button" style={{
            display: "FileUpdate" in this.props.roles
              ? ''
              : 'none'
          }} variant="primary" onClick={e => this.openPdfModal()}>Upload PDF</Button>

          <div>
            <h3>FILE INFORMATION</h3>
          </div>

          <FaUndo className="undo-item-button" style={{
            display: this.state.changed.length > 0
              ? ''
              : 'none'
          }} onClick={this.revertChanges} />
          <FiSave className="save-item-button" style={{
            display: this.state.changed.length > 0
              ? ''
              : 'none'
          }} onClick={this.saveUpdates} />

          <Button className="delete-button" style={{
            display: "FileUpdate" in this.props.roles
              ? ''
              : 'none'
          }} variant="danger" onClick={e => this.setState({ showDeleteModel: true })}>Delete Record</Button>

        </div>

        <Table className="item-details-table" size="sm">
          <tbody>

            <tr>
              <td>
                <label className="font-weight-bold">CUSTOMER:
                </label>
              </td>
              <td>
                <p>{this.state.itemSelected.client}</p>
              </td>

              <td>
                <label className="font-weight-bold">PROFAST REF:
                </label>
              </td>
              <td>
                <p>{this.state.itemSelected.barcode}</p>
              </td>
            </tr>

            <tr>
              <td>
                <label className="font-weight-bold">REF NUMBER:
                </label>
              </td>
              <td>
                <p>{this.state.itemSelected.refNumber}</p>
              </td>

              <td>
                <label className="font-weight-bold">DATE CREATED:
                </label>
              </td>
              <td>
                {
                  this.state.showSummons
                    ? <p>{this.state.itemSelected.summons[0].createdOn}</p>
                    : ""
                }
                {
                  this.state.showSheriff
                    ? <p>{this.state.itemSelected.sheriff[0].createdOn}</p>
                    : ""
                }
                {
                  this.state.showJudge
                    ? <p>{this.state.itemSelected.judgement[0].createdOn}</p>
                    : ""
                }
                {
                  this.state.showOther
                    ? <p>{this.state.itemSelected.otherItem[0].createdOn}</p>
                    : ""
                }
              </td>

              <td>
                <Button variant="link" className="barcode-button" style={{
                  display: "QuickView" in this.props.roles
                    ? 'none'
                    : ''
                }} onClick={this.onPrintBarcode}><Barcode barId={this.state.itemSelected.barcode} /></Button>
              </td>
              <td></td>
            </tr>

            <tr>
              <td>
                <label className="font-weight-bold">CASE NUMBER:
                </label>
              </td>
              <td>
                <p>{this.state.itemSelected.caseNumber}</p>
              </td>
            </tr>

          </tbody>
        </Table>

        <Tabs className="item-tabs" style={{
          position: "inherit"
        }} defaultActiveKey="summons" onSelect={this.showSelectedTab}>
          <Tab eventKey="summons" title="Summons">
            {
              this.state.showSummons
                ? <SummonsTable state={this.state} onChanged={this.onChanged} auth={this.props.roles} addNewNote={this.addNewNote} saveEnteredNote={this.saveEnteredNote} removeDate={this.removeDate} />
                : ""
            }
          </Tab>
          <Tab eventKey="sheriff" title="Sheriff Return">
            {
              this.state.showSheriff
                ? <SheriffTable state={this.state} onChanged={this.onChanged} addNewNote={this.addNewNote} saveEnteredNote={this.saveEnteredNote} removeDate={this.removeDate} />
                : ""
            }
          </Tab>
          <Tab eventKey="judgement" title="Judgement">
            {
              this.state.showJudge
                ? <JudgementTable state={this.state} onChanged={this.onChanged} addNewNote={this.addNewNote} saveEnteredNote={this.saveEnteredNote} removeDate={this.removeDate} />
                : ""
            }
          </Tab>
          <Tab eventKey="other" title="Other">
            {
              this.state.showOther
                ? <OtherTable state={this.state} onChanged={this.onChanged} addNewNote={this.addNewNote} saveEnteredNote={this.saveEnteredNote} removeDate={this.removeDate} />
                : ""
            }
          </Tab>
        </Tabs>

        {
          this.state.itemSelected.summons[0].savedFiles
            ? this.showAttachmentBlock()
            : ""
        }

      </div>

    </div>);
  }

  showPDFModal() {
    return (<div>
      <Modal className="modal-save-pdf" centered="centered" show={this.state.showUploadPDFModel}>

        <Modal.Header>
          <Modal.Title >
            Upload PDF!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-selector-container">
            <FileBase64 multiple={true} onDone={this.getFiles.bind(this)} />
            <FiChevronLeft onClick={e => this.viewNextFile("back")} />
            <FiChevronRight onClick={e => this.viewNextFile("next")} />
          </div>

          <br />

          <ToggleButtonGroup type="radio" name="pdf-options" style={{
            width: "100%",
            marginBottom: "1rem"
          }}>
            <ToggleButton variant="outline-primary" value="Issued Summons" checked={this.state.is} onChange={(e) => this.onPdfRadios(e.target.value)}>
              Issued Summons
            </ToggleButton>
            <ToggleButton variant="outline-primary" value="Sheriff Delivery" checked={this.state.sd} onChange={(e) => this.onPdfRadios(e.target.value)}>
              Sheriff Delivery
            </ToggleButton>
            <ToggleButton variant="outline-primary" value="Sheriff Return" checked={this.state.sr} onChange={(e) => this.onPdfRadios(e.target.value)}>
              Sheriff Return
            </ToggleButton>
            <ToggleButton variant="outline-primary" value="Proof of Lodge" checked={this.state.pl} onChange={(e) => this.onPdfRadios(e.target.value)}>
              Proof of Lodge
            </ToggleButton>
            <ToggleButton variant="outline-primary" value="Granted Queued" checked={this.state.gq} onChange={(e) => this.onPdfRadios(e.target.value)}>
              Granted Queried
            </ToggleButton>
            <ToggleButton variant="outline-primary" value="Other" checked={this.state.ot} style={{
              width: "7rem"
            }} onChange={(e) => this.onPdfRadios(e.target.value)}>
              Other
            </ToggleButton>
          </ToggleButtonGroup>

          <br />
          <embed src={this.state.base64.file} type="application/pdf" width="100%" style={{
            height: "400px"
          }}></embed>
        </Modal.Body>
        <Modal.Footer>
          <Spinner className="save-pdf-loading" style={{
            display: this.state.pdfSaveLoading
              ? ""
              : "none"
          }} animation="border" variant="warning" />

          <Badge variant="success" className="" style={{
            fontSize: "1rem",
            display: this.state.pdfSaved
              ? ''
              : 'none'
          }}>
            Attachements Saved!
          </Badge>
          <Badge variant="danger" className="" style={{
            fontSize: "1rem",
            display: this.state.pdfSaveError
              ? ''
              : 'none'
          }}>
            Error during save.
          </Badge>

          <div style={{
            display: this.state.pdfSaved || this.state.pdfSaveError || this.state.pdfFiletype === "IS"
              ? "none"
              : ""
          }}>
            <span style={{
              marginRight: "1rem"
            }}>Ship Date:</span>
            <DatePicker id="pdf-date-shipped" dateFormat="dd/MM/yyyy" value={this.state.pdfDateShipped} onChange={date => this.setState({ pdfDateShipped: moment(date).format("DD/MM/YYYY") })} />
          </div>

          <Button variant="outline-secondary" onClick={this.onPDFDialogClose}>
            Close
          </Button>
          <Button variant="success" disabled={this.state.pdfSaveButton} onClick={this.onSaveAllPdfs}>Save Files</Button>
        </Modal.Footer>

      </Modal>
    </div>)
  }

  showAttachmentBlock() {
    return (<PdfViewer files={this.state.itemSelected.summons[0]} whatToShow={this.state} deleteSelectedPDFs={this.deleteSelectedPDFs} showPDFs={this.showPDFs} pdfsSelected={this.state.pdfsSelected} addToDelete={this.addToDelete} auth={this.props.roles} />)
  }
}
