import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { FaRegFilePdf } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { Storage } from "aws-amplify";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import Background from "../img/profast.png";
import ProgressBar from 'react-bootstrap/ProgressBar';

import * as backendApi from '../../services/api';
import NavTabs from "../home/NavTabs";

import "./Quick.css";

let zip = new JSZip();
let photoZip = zip.folder("ProFast");
let fileCount = 1;

export default class QuickView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showSummons: true,
      summonsItem: null,
      summonsColumns: [],
      showSheriff: false,
      showJudgement: false,
      showPDFButton: false,
      searchLoading: false,
      spinnerZipping: false,
      spinnerLoading: false,
      sortedIS: [],
      setSearchVal: "",
      pdfDownloadText: "",
      selectedDownloads: [],
      activeTab: "IS",
      hideImg: false,
      filesDownloaded: 0
    };
  }

  componentDidMount() {
    this.props.loading(true);
    const summonsColumns = JSON.parse(localStorage.getItem('summonsColumns'));
    const sheriffColumns = JSON.parse(localStorage.getItem('sheriffColumns'));
    const judgementColumns = JSON.parse(localStorage.getItem('judgeColumns'));

    summonsColumns.forEach((col, i) => {
      switch (col.name) {
        case "Notes":
          col.active = false;
          break;
        case "Barcode":
          col.active = false;
          break;
        default: col.active = true;
      }
    });

    sheriffColumns.forEach((col, i) => {
      switch (col.name) {
        case "Notes":
          col.active = false;
          break;
        case "Barcode":
          col.active = false;
          break;
        default: col.active = true;
      }
    });

    judgementColumns.forEach((col, i) => {
      switch (col.name) {
        case "Notes":
          col.active = false;
          break;
        case "Barcode":
          col.active = false;
          break;
        default: col.active = true;
      }
    });

    this.setState({
      summonsColumns: summonsColumns,
      sheriffColumns: sheriffColumns,
      judgementColumns: judgementColumns
    });
    this.props.loading(false);
    // this.getAllData();
  }

  getAllData = async () => {
    const myData = JSON.parse(localStorage.getItem("myData"));
    const client = myData.client;

    let isFiles = [];
    let sdFiles = [];
    let srFiles = [];
    let plFiles = [];
    let gqFiles = [];

    const summonsList = await backendApi.getAttachmentList(client);

    summonsList.forEach((item, i) => {
      const process = item.process;

      switch (process) {
        case "IS":
          isFiles.push(item);
          break;
        case "SD":
          sdFiles.push(item);
          break;
        case "SR":
          srFiles.push(item);
          break;
        case "PL":
          plFiles.push(item);
          break;
        case "GQ":
          gqFiles.push(item);
          break;
        default:
          break;
      }
    });

    this.setState({
      isFiles: isFiles,
      sdFiles: sdFiles,
      srFiles: srFiles,
      plFiles: plFiles,
      gqFiles: gqFiles
    });

    this.props.loading(false);
  }

  onDateChanged = async (date) => {
    this.setState({ pdfDateShipped: date, spinnerLoading: true, hideImg: true });
    const myData = JSON.parse(localStorage.getItem("myData"));
    const client = myData.client;
    const newDate = date.replace(/\//g, "");

    let isFiles = [];
    let sdFiles = [];
    let srFiles = [];
    let plFiles = [];
    let gqFiles = [];
    const summonsList = await backendApi.getAttachmentListDateSelected(client, newDate);

    summonsList.forEach((item, i) => {
      const process = item.process;

      switch (process) {
        case "IS":
          isFiles.push(item);
          break;
        case "SD":
          sdFiles.push(item);
          break;
        case "SR":
          srFiles.push(item);
          break;
        case "PL":
          plFiles.push(item);
          break;
        case "GQ":
          gqFiles.push(item);
          break;
        default:
          break;
      }
    });

    this.setState({
      isFiles: isFiles,
      sdFiles: sdFiles,
      srFiles: srFiles,
      plFiles: plFiles,
      gqFiles: gqFiles
    });

    this.onDateShipChanged(newDate);
  }

  onDateShipChanged = (date) => {
    // this.setState({ pdfDateShipped: date, searchLoading: true, hideImg: true });
    // const oldDate = date;
    // const newDate = date.replace(/\//g, "");

    let sortedIS = [];
    let sortedSD = [];
    let sortedSR = [];
    let sortedPL = [];
    let sortedGQ = [];

    this.state.isFiles.forEach((file) => {
      // if (file.dateShipped === newDate || file.dateShipped === oldDate) {
      //   sortedIS.push(file);
      // }
      sortedIS.push(file);
    });

    this.state.sdFiles.forEach((file) => {
      // if (file.dateShipped === newDate || file.dateShipped === oldDate) {
      //   sortedSD.push(file);
      // }
      sortedSD.push(file);
    });

    this.state.srFiles.forEach((file) => {
      // if (file.dateShipped === newDate || file.dateShipped === oldDate) {
      //   sortedSR.push(file);
      // }
      sortedSR.push(file);
    });

    this.state.plFiles.forEach((file) => {
      // if (file.dateShipped === newDate || file.dateShipped === oldDate) {
      //   sortedPL.push(file);
      // }
      sortedPL.push(file);
    });

    this.state.gqFiles.forEach((file) => {
      // if (file.dateShipped === newDate || file.dateShipped === oldDate) {
      //   sortedGQ.push(file);
      // }
      sortedGQ.push(file);
    });

    this.setState({ sortedFiles: true,
      sortedIS: sortedIS, sortedSD: sortedSD,
      sortedSR: sortedSR, sortedPL: sortedPL,
      sortedGQ: sortedGQ, searchLoading: false,
      spinnerLoading: false,
      pdfDownloadText: "Download All Issued Summons"
    });
  }

  showSelectedTab = (process) => {
    let downloadText = "Download All ";
    let activeTab = "";

    switch (process) {
      case "IS":
        downloadText += " Issued Summons";
        activeTab = "IS";
        break;
      case "SD":
        downloadText += " Sheriff Deliveries";
        activeTab = "SD";
        break;
      case "SR":
        downloadText += " Sheriff Returns";
        activeTab = "SR";
        break;
      case "PL":
        downloadText += " Proof of Lodges";
        activeTab = "PL";
        break;
      case "GQ":
        downloadText += " Granted Queries";
        activeTab = "GQ";
        break;
      default:
        break;
    }

    this.setState({
      selectedDownloads: [],
      pdfDownloadText: downloadText,
      activeTab: activeTab
    })
  }

  onCheckSelected = (e, item, process) => {
    let downloadText = "Download ";
    let selectedDownloads = this.state.selectedDownloads;
    var checked = e.target.checked;

    if (checked) {
      let addEntry = true;
      for (var i = 0; i < selectedDownloads.length; i++) {
        const itemName = selectedDownloads[i].name;
        if (itemName === item.name) {
          addEntry = false;
        }
      }

      if (addEntry) {
        selectedDownloads.push(item);
      }

    } else {
      for (var j = 0; j < selectedDownloads.length; j++) {
        const itemName = selectedDownloads[j].name;
        if (itemName === item.name) {
          selectedDownloads.splice(j, 1);
        }
      }
    }

    switch (process) {
      case "IS":
        downloadText += selectedDownloads.length > 0 ? selectedDownloads.length : "All";
        downloadText += " Issued Summons";
        break;
      case "SD":
        downloadText += selectedDownloads.length > 0 ? selectedDownloads.length : "All";
        downloadText +=  " Sheriff Deliveries";
        break;
      case "SR":
        downloadText += selectedDownloads.length > 0 ? selectedDownloads.length : "All";
        downloadText +=  " Sheriff Returns";
        break;
      case "PL":
        downloadText += selectedDownloads.length > 0 ? selectedDownloads.length : "All";
        downloadText +=  " Proof of Lodges";
        break;
      case "GQ":
        downloadText += selectedDownloads.length > 0 ? selectedDownloads.length : "All";
        downloadText +=  " Granted Queries";
        break;
      default:
        break;
    }

    this.setState({
      selectedDownloads: selectedDownloads,
      pdfDownloadText: downloadText
    });
  }

  showPDFs = async (file) => {
    const fileUrl = await Storage.get(file.key);
    window.open(fileUrl);
  }

  onSaveAllPdfs = async () => {
    this.setState({ searchLoading: true });
    let files = [];

    if (this.state.selectedDownloads.length > 0) {
      files = this.state.selectedDownloads;
    } else {
      switch (this.state.activeTab) {
        case "IS":
          files = this.state.sortedIS;
          break;
        case "SD":
          files = this.state.sortedSD;
          break;
        case "SR":
          files = this.state.sortedSR;
          break;
        case "PL":
          files = this.state.sortedPL;
          break;
        case "GQ":
          files = this.state.sortedGQ;
          break;
        default:
          break;
      }
    }


    for (let i = 0; i < files.length; i++) {
      let success = await this.DownloadFileFromS3(files[i]);

      if (success) {
        await this.updateProgressBar(files.length);
        await this.waitaSec(500);
        fileCount++;
      } else {
        alert("Error in downloading file: " + files[i].key );
        // break;
      }

    }

    this.setState({ spinnerZipping: true });

    zip.generateAsync({type:"blob"}).then(function(content) {
      saveAs(content, "ProFast");

      zip = new JSZip();
      photoZip = zip.folder("ProFast");
    });

    setTimeout(() => {
      this.setState({ searchLoading: false, filesDownloaded: 0, spinnerZipping: false });
      fileCount = 1;
    }, 2000);

  }

  updateProgressBar = (length) => {
    let perc = (fileCount / length) * 100;

    if (perc >= 100 ) {
      this.setState({ filesDownloaded: 100 });
    } else {
      this.setState({ filesDownloaded: perc.toFixed(0) });
    }

  }

  waitaSec (milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  DownloadFileFromS3 = async (file) => {
    let result = "";
    let success = false;
    try {
      result = await Storage.get(file.key, {download: true});
      let mimeType = result.ContentType
      let fileName = file.name.split("-");
      fileName = fileName[1] + "_" + fileName[2].replace(/\//g, '.') + "_" + fileName[3];
      let blob = new Blob([result.Body], {type: mimeType})

      photoZip.file(fileName, blob);
      success = true;
    } catch (e) {
      console.log(e);
    }

    return success;
  }

  render() {
    return (
      <div className="QuickView">

        <NavTabs className="nav-tabs" rootProps={this.props.history} auth={this.props.roles}/>

        <img src={Background} alt="img" style={{display: this.state.hideImg ? "none" : ""}} className="background-home-img-quick"/>

        <div className="heading-quick-view">
          <span>Select Shipment Date: </span>
          <DatePicker id="client-date-shipped" dateFormat="dd/MM/yyyy" value={this.state.pdfDateShipped}
            onChange={date => this.onDateChanged(moment(date).format("DD/MM/YYYY"))}/>

          <div style={{display: this.state.spinnerZipping ? "" : "none" }}>
            <span className="quick-zipping-text">Zipping...</span>
            <Spinner className="quick-zipping" animation="border" variant="warning"/>
          </div>
          <div style={{display: this.state.spinnerLoading ? "" : "none" }}>
            <span className="quick-zipping-text">Loading...</span>
            <Spinner className="quick-zipping" animation="border" variant="warning"/>
          </div>
          <ProgressBar now={this.state.filesDownloaded} label={`${this.state.filesDownloaded} %`}
            style={{display: this.state.searchLoading ? "" : "none" }} className="quick-search-loading"/>

          <div className="download-pdf-button" onClick={this.onSaveAllPdfs}
            style={{display: this.state.pdfDownloadText !== "" ? "flex" : "none" }}>
            <FaRegFilePdf/>
            <p>{this.state.pdfDownloadText}</p>
          </div>


        </div>

        {this.state.sortedFiles ? this.showItemDetails() : ""}

      </div>
    );
  }

  showItemDetails() {
    return (

      <div style={{margin: "1rem"}}>

        <Tabs className="item-tabs" style={{position: "inherit"}} activeKey={this.state.activeTab} onSelect={this.showSelectedTab}>
          <Tab eventKey="IS" title="Issued Summons">

            {this.state.activeTab === "IS" ?
              <Form style={{height: "calc(100vh - 23rem)", overflowY: "auto"}}>

                {this.state.sortedIS.map((item, index) => (

                  <div key={index} className="quick-pdfList-form">

                    <Form.Check type="checkbox" id={`${item.id}-${index}`}>

                      <Form.Check.Input type="checkbox" onClick={e => this.onCheckSelected(e, item, "IS")}/>

                      <Form.Check.Label>
                        <FaRegFilePdf/>
                        <p>{item.name}</p>
                        <FiDownload className="pdf-item-download" onClick={e => this.showPDFs(item)}/>
                      </Form.Check.Label>
                    </Form.Check>

                  </div>

                ))}

              </Form>
            : "" }
          </Tab>

          <Tab eventKey="SD" title="Sheriff Delivery">

            {this.state.activeTab === "SD" ?
              <Form style={{height: "calc(100vh - 23rem)", overflowY: "auto"}}>

                {this.state.sortedSD.map((item, index) => (

                  <div key={index} className="quick-pdfList-form" >
                    <Form.Check type="checkbox" id={`${item.id}-${index}`} >
                      <Form.Check.Input type="checkbox" onClick={e => this.onCheckSelected(e, item, "SD")}/>
                      <Form.Check.Label>
                        <FaRegFilePdf/>
                        <p>{item.name}</p>

                        <FiDownload className="pdf-item-download" onClick={e => this.showPDFs(item)}/>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>

                ))}

              </Form>
            : "" }
          </Tab>

          <Tab eventKey="SR" title="Sheriff Returns">

            {this.state.activeTab === "SR" ?
              <Form style={{height: "calc(100vh - 23rem)", overflowY: "auto"}}>

                {this.state.sortedSR.map((item, index) => (

                  <div key={index} className="quick-pdfList-form">
                    <Form.Check type="checkbox" id={`${item.id}-${index}`}>
                      <Form.Check.Input type="checkbox" onClick={e => this.onCheckSelected(e, item, "SR")}/>
                      <Form.Check.Label>
                        <FaRegFilePdf/>
                        <p>{item.name}</p>

                        <FiDownload className="pdf-item-download" onClick={e => this.showPDFs(item)}/>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>

                ))}

              </Form>
            : "" }

          </Tab>

          <Tab eventKey="PL" title="Proof of Lodge">

            {this.state.activeTab === "PL" ?
              <Form style={{height: "calc(100vh - 23rem)", overflowY: "auto"}}>

                {this.state.sortedPL.map((item, index) => (

                  <div key={index} className="quick-pdfList-form">
                    <Form.Check type="checkbox" id={`${item.id}-${index}`}>
                      <Form.Check.Input type="checkbox" onClick={e => this.onCheckSelected(e, item, "PL")}/>
                      <Form.Check.Label>
                        <FaRegFilePdf/>
                        <p>{item.name}</p>

                        <FiDownload className="pdf-item-download" onClick={e => this.showPDFs(item)}/>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>

                ))}

              </Form>
            : "" }

          </Tab>
          <Tab eventKey="GQ" title="Granted Queried">

            {this.state.activeTab === "GQ" ?
              <Form style={{height: "calc(100vh - 23rem)", overflowY: "auto"}}>

                {this.state.sortedGQ.map((item, index) => (

                  <div key={index} className="quick-pdfList-form">
                    <Form.Check type="checkbox" id={`${item.id}-${index}`}>
                      <Form.Check.Input type="checkbox" onClick={e => this.onCheckSelected(e, item, "GQ")}/>
                      <Form.Check.Label>
                        <FaRegFilePdf/>
                        <p>{item.name}</p>

                        <FiDownload className="pdf-item-download" onClick={e => this.showPDFs(item)}/>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>

                ))}

              </Form>
            : "" }

          </Tab>
        </Tabs>

      </div>

    )
  }
}
