import React from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { FiPlusCircle, FiSave } from "react-icons/fi";
import moment from 'moment';
import DatePicker from "react-datepicker";

const OtherTable = (props) => (
  <div style={{marginTop: "1rem"}}>
    <Table striped bordered hover size="sm" responsive="sm" className="item-table other-item-table">
      <thead>
        <tr>
          {props.state.otherColumns.map((column, index) => (
            <th key={index} style={{ display: column.active ? '' : 'none' }}>
              {column.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>

        <tr>

          <td>

            <Form.Control as="select" custom value={props.state.itemSelected.client} disabled={props.state.readOnly}
              onChange={e => props.onChanged("other", "client", e.target.value)}>

              {props.state.clients.map((client, index) => (
                <option key={index}>{client.name}</option>
              ))}

            </Form.Control>

          </td>

          <td>
            <Form.Control as="select" custom value={props.state.itemSelected.otherItem[0].process} disabled={props.state.readOnly}
              onChange={e => props.onChanged("other", "process", e.target.value)}>

              {props.state.process.map((process, index) => (
                <option key={index}>{process.name}</option>
              ))}

            </Form.Control>
          </td>

          {props.state.itemSelected.client === "THIPA INCORPORATED" ? '' :

            <td>

              <Form.Control as="select" custom value={props.state.itemSelected.otherItem[0].court} disabled={props.state.readOnly}
                onChange={e => props.onChanged("other", "court", e.target.value)}>

                {props.state.courts.map((court, index) => (
                  <option key={index}>{court.name}</option>
                ))}

              </Form.Control>

            </td>
          }

          <td>

            <Form.Control as="select" custom value={props.state.itemSelected.otherItem[0].sheriff} disabled={props.state.readOnly}
              onChange={e => props.onChanged("other", "sheriff", e.target.value)}>

              {props.state.sheriffs.map((sheriff, index) => (
                <option key={index}>{sheriff.name}</option>
              ))}

            </Form.Control>

          </td>

          <td>

            <Form.Control id="refNumber" type="text" value={props.state.itemSelected.refNumber}
              onChange={e => props.onChanged("summons", "refNumber", e.target.value)} readOnly={props.state.readOnly} />

          </td>

          <td>

            <Form.Control id="caseNumber" type="text" value={props.state.itemSelected.otherItem[0].caseNumber}
              onChange={e => props.onChanged("other", "caseNumber", e.target.value)} readOnly={props.state.readOnly} />

          </td>

          <td>

            <Form.Control as="select" custom value={props.state.itemSelected.otherItem[0].otherStatus} disabled={props.state.readOnly}
              onChange={e => props.onChanged("other", "otherStatus", e.target.value)}>

              {props.state.status.map((status, index) => (
                <option key={index}>{status.name}</option>
              ))}

            </Form.Control>

          </td>

          <td>

            <DatePicker id="toSheriff" dateFormat="dd/MM/yyyy" value={props.state.itemSelected.otherItem[0].toSheriff} disabled={props.state.readOnly}
              onChange={date => props.onChanged("other", "toSheriff", moment(date).format("DD/MM/YYYY"))}
              onChangeRaw={(event) => props.removeDate("other", "toSheriff")}/>

          </td>

          {props.state.itemSelected.client === "THIPA INCORPORATED" ? '' :

            <td>

              <DatePicker id="statusDate" dateFormat="dd/MM/yyyy" value={props.state.itemSelected.otherItem[0].statusDate} disabled={props.state.readOnly}
                onChange={date => props.onChanged("other", "statusDate", moment(date).format("DD/MM/YYYY"))}
                onChangeRaw={(event) => props.removeDate("other", "statusDate")}/>

            </td>
          }

          {props.state.itemSelected.client === "THIPA INCORPORATED" ? '' :

            <td>

              <Form.Control id="batchNumber" type="text" value={props.state.itemSelected.batchNumber}
                onChange={e => props.onChanged("other", "batchNumber", e.target.value)} readOnly={props.state.readOnly} />

            </td>
          }

          <td>

            <DatePicker popperPlacement="left-start" id="toClient"
              dateFormat="dd/MM/yyyy"
              value={props.state.itemSelected.otherItem[0].toClient}
              disabled={props.state.readOnly}
              onChange={date => props.onChanged("other", "toClient", moment(date).format("DD/MM/YYYY"))}
              onChangeRaw={(event) => props.removeDate("other", "toClient")}/>

          </td>

        </tr>

      </tbody>
    </Table>

    <Accordion className="notes-accordion" defaultActiveKey="0">
      <Card>
        <Card.Header>
          <Accordion.Toggle className="notes-toggle-button" as={Button} variant="text" eventKey="0">
            Show Notes
          </Accordion.Toggle>
          <FiPlusCircle className="ml-2 add-note-icon" onClick={e => props.addNewNote("other")}
            style={{display: props.state.readOnly ? "none" : ""}}/>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>

            <Table className="item-notes-table" size="sm">
              <tbody>

                {props.state.itemSelected.otherItem[0].notes.map((note, index) => (
                  <tr key={index}>
                    <td style={{width: "7%"}}>{note.createdOn}</td>
                    <td style={{width: "13%"}}>{note.createdBy}</td>
                    <td style={{width: "80%"}}>
                      {note.new ?

                        <InputGroup className="">
                          <Form.Control placeholder="Enter note text" onChange={e => note.note = e.target.value}/>
                          <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                              <FiSave onClick={e => props.onChanged("other", "note", note, index)} />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>

                      : note.note}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>
);

export default OtherTable;
