import React from 'react';
import { useBarcode } from 'react-barcodes';

function Barcode(ref) {
  const { inputRef } = useBarcode({
    value: ref.barId,
    options: {
      format: "ean13",
      flat: true,
      height: 50,
      width: 1.2,
      fontSize: 15
    }
  });

  return <div id="div-svg">
          <svg id="barcode-canvas" ref={inputRef}/>
         </div>;
};

export default Barcode;
