import React from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { FiPlusCircle, FiSave } from "react-icons/fi";
import { FaUndo } from "react-icons/fa";

const Users = (props) => (
  <div style={{marginLeft: "1rem"}}>

    <Table striped bordered hover size="sm" responsive="sm" className="man-scroll-table man-table">
      <thead>
        <tr>
          <th style={{width: "20rem"}}>Name</th>
          <th style={{width: "20rem"}}>Email</th>
          <th style={{width: "20rem"}}>Password</th>
          <th style={{width: "20rem"}}>Print Location</th>
          <th style={{width: "20rem"}}>Client Limit</th>
          <th style={{width: "20rem"}}>Roles</th>
          <th style={{width: "7rem", maxWidth: "7rem", textAlign: "center"}}>Send</th>
          <th style={{width: "8rem", maxWidth: "8rem", textAlign: "center"}}>Disable</th>
        </tr>
      </thead>
      <tbody>

        {props.state.newUsers.map((row, index) => (
          <tr key={index}>
            <td style={{width: "15rem"}}>
              <Form.Control type="text" value={row.name}
                onChange={e => props.onChanged(index, "name" + e.target.value, "users")} />
            </td>

            <td>
              <Form.Control type="text" value={row.email}
                onChange={e => props.onChanged(index, "email" + e.target.value, "users")} />
            </td>

            <td>
              <Form.Control type="text" value={row.password}
                onChange={e => props.onChanged(index, "pass" + e.target.value, "users")} />
            </td>

            <td>
              <Form.Control as="select" custom value={row.printLocation}
                onChange={e => props.onChanged(index, "print" + e.target.value, "users")}>

                <option key="default">Default</option>
                <option key="print1">Print 1</option>
                <option key="print2">Print 2</option>

              </Form.Control>
            </td>

            <td>
              <Form.Control as="select" custom value={row.client}
                onChange={e => props.onChanged(index, "client" + e.target.value, "users")}>

                {props.state.clients.map((client, i) => (
                  <option key={i}>{client.name}</option>
                ))}

              </Form.Control>
            </td>

            <td>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="man-roles-drop">
                  {row.roleNames}
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  <Form>

                    <div>
                      <Form.Check id="check-file" type="checkbox" label="File Update" className="m-2"
                        onClick={e => props.onChanged(index, "FileUpdate", "users")} />
                    </div>
                    <div>
                      <Form.Check id="check-manage" type="checkbox" label="Management" className="m-2"
                        onClick={e => props.onChanged(index, "Management", "users")} />
                    </div>
                    <div>
                      <Form.Check id="check-new" type="checkbox" label="New Files" className="m-2"
                        onClick={e => props.onChanged(index, "NewFiles", "users")} />
                    </div>
                    <div>
                      <Form.Check id="check-quick" type="checkbox" label="Quick View" className="m-2"
                        onClick={e => props.onChanged(index, "QuickView", "users")} />
                    </div>
                    <div>
                      <Form.Check id="check-report" type="checkbox" label="Reports" className="m-2"
                        onClick={e => props.onChanged(index, "Reports", "users")} />
                    </div>

                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </td>

            <td></td>
            <td></td>
          </tr>
        ))}

        {props.state.users.map((row, index) => (
          <tr key={index}>
            <td style={{width: "15rem"}}>
              {row.firstName} {row.lastName}
            </td>

            <td>
              {row.email}
            </td>

            <td>
              {row.password}
            </td>

            <td>
              {row.printLoc}
            </td>

            <td>
              {row.client}
            </td>

            <td>
              <ul style={{display: row.userRoles.length > 0 ? "" : "note"}}>
                {row.userRoles.map((item, index) => (
                  <li> {item.name} </li>
                ))}
              </ul>
            </td>

            <td style={{width: "7rem", maxWidth: "7rem", textAlign: "center"}}>
              <Button style={{width: "100%", backgroundColor: "transparent", color: "#333"}}
                onClick={() => props.sendUserDetails(index, row)}>Send</Button>
            </td>

            <td style={{width: "8rem", maxWidth: "8rem", textAlign: "center"}}>
              <Button style={{width: "7rem"}} variant="link"
                onClick={() => props.onChanged(index, row.userStatus, "users")}>{row.userStatus}</Button>
            </td>
          </tr>
        ))}

      </tbody>
    </Table>

    <FiPlusCircle className="add-man-button ml-4" onClick={e => props.onAddEntry("users")}
      style={{display: props.state.newUsers.length > 0 ? "none" : ""}}/>

    <FaUndo className="undo-man-button ml-4" onClick={e => props.onChanged("", "delete", "users")}
      style={{display: props.state.newUsers.length > 0 ? "" : "none"}}/>

    <FiSave className="save-man-button ml-4" onClick={e => props.onSaveEntry("users")}
      style={{display: props.changed ? "" : "none"}}/>

  </div>
);

export default Users;
