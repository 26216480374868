import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

const ThFilter = (props) => (
  <div>

    <Dropdown.Menu className="report-filter-dropdown-menu">
      <Form.Control id="searchInput" style={{ display: props.field.includes("date") ? "none" : "" }}
        type="text" onChange={e => props.filterDropdowns(e.target.value, props.field)} />

      <Form.Control id="searchDateInput" style={{ display: props.field.includes("date") ? "" : "none" }}
        type="text" onChange={e => props.onCheckSelected(e, {name: e.target.value}, props.field, true)} />
      <Dropdown.Divider />

      <Form style={{height: "calc(100vh - 23rem)", overflowY: "auto"}}>

        {props.list.map((item, index) => (

          <div key={index}>
            <Form.Check id={`${props.field}-${index}`} custom type="checkbox" label={item.name} className="m-2"
              onClick={e => props.onCheckSelected(e, item, props.field)}/>
          </div>

        ))}

      </Form>

    </Dropdown.Menu>

  </div>
);

export default ThFilter;
