import React, { Component } from 'react';
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import { ToastContainer, toast, Flip } from 'react-toastify';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import NavTabs from "../home/NavTabs";
import moment from 'moment';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

import ThFilter from "./ThFilter";

import "./Report.css";

import * as backendApi from '../../services/api';

export default class ReportSummons extends Component {

  notifyError = () => toast.error('Too much data to fetch. Try select using 3 or 6 month intervals.', {delay: 0, containerId: 'error', autoClose: 2000});

  constructor(props) {
    super(props);

    this.state = {
      reportSummons: [],
      clients: [],
      courts: [],
      process: [],
      plaintiffs: [],
      sheriffs: [],
      filterArray: [],
      filterLookup: {},
      dateSelections: [],
      clientName: "",
      timeframe: "2024",
      month3Check: false,
      month6Check: false,
      lastKeyRef: "",
      client: "",
      isClient: false
    };
  }

  componentDidMount() {
    const myData = JSON.parse(localStorage.getItem("myData"));

    const clients = JSON.parse(localStorage.getItem('clients'));
    const courts = JSON.parse(localStorage.getItem('courts'));
    const process = JSON.parse(localStorage.getItem('process'));
    const plaintiffs = JSON.parse(localStorage.getItem('plaintiffs'));
    const sheriffs = JSON.parse(localStorage.getItem('sheriffs'));
    const yearNow = new Date().getFullYear();

    var isClient = false;
    myData.userRoles.forEach((item, i) => {
      if (item.name === "QuickView") {
        this.setState({isClient: true});
        isClient = true;
      }
    });

    if (isClient) {
      for (var c = 0; c < clients.length; c++) {
        let client = clients[c].name;

        if (client !== myData.client) {
          clients.splice(c, 1);
          c--;
        }
      }
    }

    this.setState({
      clients: clients,
      courts: courts,
      process: process,
      plaintiffs: plaintiffs,
      sheriffs: sheriffs,
      dateSelections: [{
        name: "Last Week"
      },{
        name: "Last Month"
      },{
        name: "Last Year"
      }],
      timeframe: yearNow
    });

    if (isClient) {
      this.onClientSelected(myData.client);
    }

  }

  onClientSelected = async (client) => {
    this.setState({
      reportSummons: [],
      unfilteredRows: [],
      filterArray: [],
      filterLookup: {},
      client: client,
      keyRefList: []
    });

    let keyRefList = [];
    if (client !== "") {
      this.props.loading(true);

      try {

        const allItems = await backendApi.getReportList("isSummons", client, this.state.timeframe, "");
        let unfilteredRows = [];
        let reports = [];

        allItems.forEach((item) => {
          if (item.lastKeyRef) {
            this.setState({ lastKeyRef: item.lastKeyRef });
            keyRefList.push({ lastKeyRef: item.lastKeyRef });
          } else {
            item[0].shipToCourt = item[0].shipToCourt === undefined ? null : item[0].shipToCourt;
            item[0].shipFromCourt = item[0].shipFromCourt === undefined ? null : item[0].shipFromCourt;
            reports.push(item[0]);
            unfilteredRows.push(item[0]);
          }
        });

        this.setState({
          reportSummons: reports,
          unfilteredRows: unfilteredRows,
          clientName: client,
          keyRefList: keyRefList
        });

        this.props.loading(false);

      } catch (e) {
        console.log(e);
        this.props.loading(false);
        this.notifyError();
      }
    } else {
      this.setState({ month3Check: false, month6Check: false });
    }
  }

  onTimeframeSelected = (year) => {
    // this.setState({ timeframe: year });

    this.setState({ timeframe: year }, () => {
      if (this.state.isClient) {
        const myData = JSON.parse(localStorage.getItem("myData"));
        this.onClientSelected(myData.client);
      }
    });

  }

  filterDate = (value, field, filteredItems) => {
    let lastWeekStart = moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD");
    let lastWeekEnd = moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD");
    let lastMonthStart = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    let lastMonthEnd = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
    let lastYearStart = moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD");
    let lastYearEnd = moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD");

    let rows = [];

    let dateToClient = "";
    let dateCreatedOn = "";
    let dateToSheriff = "";
    let dateIssueDate = "";

    switch (value.name) {

      case "Last Week":
        console.log(lastWeekStart + " : " + lastWeekEnd);

        filteredItems.forEach((row, i) => {
          let isInRange = false;

          dateToClient = row.toClient.substr(6,4) + "-" + row.toClient.substr(3,2) + "-" + row.toClient.substr(0,2);
          dateCreatedOn = row.createdOn.substr(6,4) + "-" + row.createdOn.substr(3,2) + "-" + row.createdOn.substr(0,2);
          dateToSheriff = row.toSheriff.substr(6,4) + "-" + row.toSheriff.substr(3,2) + "-" + row.toSheriff.substr(0,2);
          dateIssueDate = row.issueDate.substr(6,4) + "-" + row.issueDate.substr(3,2) + "-" + row.issueDate.substr(0,2);

          switch (field) {
            case "dateCreate":
              isInRange = moment(dateCreatedOn).isBetween(lastWeekStart, lastWeekEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateSheriff":
              isInRange = moment(dateToSheriff).isBetween(lastWeekStart, lastWeekEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateIssue":
              isInRange = moment(dateIssueDate).isBetween(lastWeekStart, lastWeekEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateClient":
              isInRange = moment(dateToClient).isBetween(lastWeekStart, lastWeekEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;
            default:
          }
        });

        break;

      case "Last Month":
        console.log(lastMonthStart + " : " + lastMonthEnd);

        filteredItems.forEach((row, i) => {
          let isInRange = false;
          dateToClient = row.toClient.substr(6,4) + "-" + row.toClient.substr(3,2) + "-" + row.toClient.substr(0,2);
          dateCreatedOn = row.createdOn.substr(6,4) + "-" + row.createdOn.substr(3,2) + "-" + row.createdOn.substr(0,2);
          dateToSheriff = row.toSheriff.substr(6,4) + "-" + row.toSheriff.substr(3,2) + "-" + row.toSheriff.substr(0,2);
          dateIssueDate = row.issueDate.substr(6,4) + "-" + row.issueDate.substr(3,2) + "-" + row.issueDate.substr(0,2);

          switch (field) {
            case "dateCreate":
              isInRange = moment(dateCreatedOn).isBetween(lastMonthStart, lastMonthEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateSheriff":
              isInRange = moment(dateToSheriff).isBetween(lastMonthStart, lastMonthEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateIssue":
              isInRange = moment(dateIssueDate).isBetween(lastMonthStart, lastMonthEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateClient":

              isInRange = moment(dateToClient).isBetween(lastMonthStart, lastMonthEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;
            default:
          }
        });
        break;

      case "Last Year":
        console.log(lastYearStart + " : " + lastYearEnd);

        filteredItems.forEach((row, i) => {
          let isInRange = false;
          dateToClient = row.toClient.substr(6,4) + "-" + row.toClient.substr(3,2) + "-" + row.toClient.substr(0,2);
          dateCreatedOn = row.createdOn.substr(6,4) + "-" + row.createdOn.substr(3,2) + "-" + row.createdOn.substr(0,2);
          dateToSheriff = row.toSheriff.substr(6,4) + "-" + row.toSheriff.substr(3,2) + "-" + row.toSheriff.substr(0,2);
          dateIssueDate = row.issueDate.substr(6,4) + "-" + row.issueDate.substr(3,2) + "-" + row.issueDate.substr(0,2);

          switch (field) {
            case "dateCreate":
              isInRange = moment(dateCreatedOn).isBetween(lastYearStart, lastYearEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateSheriff":
              isInRange = moment(dateToSheriff).isBetween(lastYearStart, lastYearEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateIssue":
              isInRange = moment(dateIssueDate).isBetween(lastYearStart, lastYearEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateClient":
              isInRange = moment(dateToClient).isBetween(lastYearStart, lastYearEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;
            default:
          }
        });
        break;
      default:

        if (value.name.length > 3) {
          filteredItems.forEach((row, i) => {
            switch (field) {
              case "dateCreate":
                if (row.createdOn !== null && row.createdOn.includes(value.name)) {
                  rows.push(row);
                }
                break;

              case "dateIssue":
                if (row.issueDate !== null && row.issueDate.includes(value.name)) {
                  rows.push(row);
                }
                break;

              case "dateSheriff":
                if (row.toSheriff !== null && row.toSheriff.includes(value.name)) {
                  rows.push(row);
                }
                break;

              case "dateClient":
                if (row.toClient !== null && row.toClient.includes(value.name)) {
                  rows.push(row);
                }
                break;
              case "dateToCourt":
                if (row.shipToCourt !== null && row.shipToCourt.includes(value.name)) {
                  rows.push(row);
                }
                break;
              case "dateFromCourt":
                if (row.shipFromCourt !== null && row.shipFromCourt.includes(value.name)) {
                  rows.push(row);
                }
                break;
              default:
            }
          });
        } else {
          rows = filteredItems;
        }
    }

    return rows;
  }

  filterDropdowns = (value, field) => {

    value = value.toUpperCase();

    let clients = JSON.parse(localStorage.getItem('clients'));
    let courts = JSON.parse(localStorage.getItem('courts'));
    let process = JSON.parse(localStorage.getItem('process'));
    let plaintiffs = JSON.parse(localStorage.getItem('plaintiffs'));
    let sheriffs = JSON.parse(localStorage.getItem('sheriffs'));

    switch (field) {
      case "client":
        clients = clients.filter(item => item.name.includes(value));
        break;
      case "court":
        courts = courts.filter(item => item.name.includes(value));
        break;
      case "process":
        process = process.filter(item => item.name.includes(value));
        break;
      case "plaintiff":
        plaintiffs = plaintiffs.filter(item => item.name.includes(value));
        break;
      case "sheriff":
        sheriffs = sheriffs.filter(item => item.name.includes(value));
        break;
      default:

    }

    this.setState({
      clients: clients,
      courts: courts,
      process: process,
      plaintiffs: plaintiffs,
      sheriffs: sheriffs
    });
  }

  filterEntries = (filteredItems, filter, fromInput) => {
    let rows = [];
    const column = filter.columnName;

    filteredItems.forEach((row, i) => {

      switch (column) {
        case "refNumber":
        case "caseNumber":
        case "batchNumber":

          for (var val in filter.values) {
            if (row[column].includes(val)) {
              rows.push(row);
            }
          }

          break;
        default:
          if (row[column] in filter.values) {
            rows.push(row);
          }
      }

    });

    return rows;
  }

  onCheckSelected = (e, item, field, fromInput) => {

    let filterArray = this.state.filterArray;
    let filteredItems = this.state.unfilteredRows;
    let filterLookup = this.state.filterLookup;

    if (!(field in filterLookup)) {

      let obj = {
        columnName: field, //client, process, count etc
        values: {}
      }

      obj.values[item.name] = 1;

      filterLookup[field] = 1;

      filterArray.push(obj);
    } else if (!e.target.checked && !fromInput && !field.includes("date")) {

      for (var i = 0; i < filterArray.length; i++) {
        if (filterArray[i].columnName === field) {
          delete filterArray[i].values[item.name];
        }
        let removeFilter = true;
        for (var prop in filterArray[i].values) {
          if (filterArray[i].values.hasOwnProperty(prop)) {
            removeFilter = false;
          }
        }
        if (removeFilter) {
          filterArray.splice(i, 1);
          i--;
          delete filterLookup[field];
        }

      }

    } else {
      filterArray.forEach((filter, i) => {

        if (fromInput && filter.columnName === field) {

          for (var val in filter.values) {
            delete filter.values[val];
            filter.values[item.name] = 1;
            break;
          }

        } else if (filter.columnName === field) {
          filter.values[item.name] = 1;
        }

      });
    }

    if (filterArray.length !== 0) {

      filterArray.forEach((filter, i) => {

        if (filter.columnName.includes("date")) {

          for (var prop in filter.values) {
              item = { name: prop };
          }

          filteredItems = this.filterDate(item, filter.columnName, filteredItems);
        } else {
          filteredItems = this.filterEntries(filteredItems, filter, fromInput);
        }

      });
    }

    this.setState({
      reportSummons: filteredItems,
      filterArray: filterArray,
      filterLookup: filterLookup
    });
  }

  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  saveAsExcel = async () => {
    var data = [];

    this.state.reportSummons.forEach((row) => {
      var obj = {
        "dateCreated": row.createdOn,
        "client": row.client,
        "process": row.process,
        "court": row.court,
        "ref": row.refNumber,
        "plaintiff": row.plaintiff,
        "case": row.caseNumber,
        "batch": row.batchNumber,
        "issue": row.issueDate,
        "sheriff": row.sheriff,
        "toSheriff": row.toSheriff,
        "toClient": row.toClient,
        "shipToCourt": row.shipToCourt,
        "shipFromCourt": row.shipFromCourt
      }
      data.push(obj);
    });

    let header = ["Date Created", "Client", "Process", "Court",
    "Ref Number", "Plaintiff", "Case Number", "Batch",
    "Issue Date", "Sheriff", "To Sheriff", "To Client", "Ship To", "Ship From"];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheetData = this.getSheetData(data, header);
      workbook.sheet(0).cell("A1").value(sheetData);

      workbook.sheet(0).row(1).style("bold", true);

      workbook.sheet(0)._rows.forEach((item, i) => {
        if (i > 1) {
          let date = item.cell(1).value();
          let dateday = date.substr(0, 2);
          let datemonth = date.substr(3, 2);
          let dateyear = date.substr(6, 4);

          if (date !== "") {
            item.cell(1).value(new Date(dateyear, datemonth - 1, dateday)).style("numberFormat", "dd/MM/yyyy");
          }

          date = item.cell(9).value();
          dateday = date.substr(0, 2);
          datemonth = date.substr(3, 2);
          dateyear = date.substr(6, 4);

          if (date !== "") {
            item.cell(9).value(new Date(dateyear, datemonth - 1, dateday)).style("numberFormat", "dd/MM/yyyy");
          }

          date = item.cell(11).value();
          dateday = date.substr(0, 2);
          datemonth = date.substr(3, 2);
          dateyear = date.substr(6, 4);

          if (date !== "") {
            item.cell(11).value(new Date(dateyear, datemonth - 1, dateday)).style("numberFormat", "dd/MM/yyyy");
          }

          date = item.cell(12).value();
          dateday = date.substr(0, 2);
          datemonth = date.substr(3, 2);
          dateyear = date.substr(6, 4);

          if (date !== "") {
            item.cell(12).value(new Date(dateyear, datemonth - 1, dateday)).style("numberFormat", "dd/MM/yyyy");
          }
        }

      });

      const totalColumns = sheetData[0].length;
      const endColumn = String.fromCharCode(64 + totalColumns);
      const rangeText = "A1:" + endColumn + "1";

      const r = workbook.sheet(0).range(rangeText);
      r.style("border", true);

      workbook.sheet(0).column("A").width(12);
      workbook.sheet(0).column("B").width(20);
      workbook.sheet(0).column("C").width(33);
      workbook.sheet(0).column("D").width(15);
      workbook.sheet(0).column("E").width(15);
      workbook.sheet(0).column("I").width(12);
      workbook.sheet(0).column("J").width(20);
      workbook.sheet(0).column("K").width(12);
      workbook.sheet(0).column("L").width(12);

      return workbook.outputAsync().then((res) => {
        saveAs(res, "SummonsReport.xlsx");
      });
    });
  }

  exportPDF = () => {
    const unit = "px";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 20;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Summons Report";

    const headers = [[
      "Date Created", "Client", "Process", "Court",
      "Ref Number", "Plaintiff", "Case Number", "Batch",
      "Issue Date", "Sheriff", "To Sheriff", "To Client"
    ]];

    let tableData = this.state.reportSummons;

    tableData.forEach((item, i) => {
      item.issueDate = item.issueDate !== "" ? item.issueDate : "";
      item.toSheriff = item.toSheriff !== "" ? item.toSheriff : "";
      item.toClient = item.toClient !== "" ? item.toClient : "";
    });

    const data = tableData.map(elt=> [
      elt.createdOn, elt.client, elt.process,
      elt.court, elt.refNumber, elt.plaintiff,
      elt.caseNumber, elt.batchNumber, elt.issueDate,
      elt.sheriff, elt.toSheriff, elt.toClient
    ]);

    let content = {
      startY: 40,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 20);
    doc.autoTable(content);
    doc.save("SummonsReport.pdf")
  }

  onMonthsSelected = (selected, months) => {
    this.setState({ timeframe: months, client: "" });

    if (selected && months === "3months") {
      this.setState({ month3Check: true });
      this.setState({ month6Check: false });

    } else if (selected && months === "6months") {
      this.setState({ month6Check: true });
      this.setState({ month3Check: false });

    } else if (!selected && months === "3months") {
      this.setState({ month3Check: false });
      this.setState({ month6Check: true });

    } else if (!selected && months === "6months") {
      this.setState({ month6Check: false });
      this.setState({ month3Check: true });

    }
  }

  render() {
    return(
      <div className="Summons">

        <ToastContainer enableMultiContainer containerId={'error'} transition={Flip} position={toast.POSITION.TOP_CENTER} />

        <NavTabs className="nav-tabs" rootProps={this.props.history} auth={this.props.roles}/>

        <div className="table-container">
          <div className="summons-heading-report">

            {"QuickView" in this.props.roles ?
              <div>
                <p style={{position: "absolute", left: "2rem", fontSize: "1.5rem"}}>
                  {this.state.clientName}
                </p>

                <Form.Control style={{position: "absolute", left: "12rem", width: "10rem"}} as="select" custom value={this.state.timeframe}
                  onChange={e => this.onTimeframeSelected(e.target.value)}>

                  <option key="1">2019</option>
                  <option key="2">2020</option>
                  <option key="3">2021</option>
                  <option key="4">2022</option>
                  <option key="5">2023</option>
                  <option key="6">2024</option>

                </Form.Control>
              </div>
              :

              <div className="report-select-client">
                <span>Select Client: </span>
                <Form.Control as="select" custom value={this.state.client}
                  onChange={e => this.onClientSelected(e.target.value)}>

                  {this.state.clients.map((client, index) => (
                    <option key={index} >{client.name}</option>
                  ))}

                </Form.Control>

                <Form.Control style={{ width: "10rem"}} as="select" custom value={this.state.timeframe}
                  onChange={e => this.onTimeframeSelected(e.target.value)}>

                  <option key="1">2019</option>
                  <option key="2">2020</option>
                  <option key="3">2021</option>
                  <option key="4">2022</option>
                  <option key="5">2023</option>
                  <option key="6">2024</option>

                </Form.Control>

              </div>
            }

            <div>
              <h3>SUMMONS REPORT</h3>
            </div>

            <FaRegFileExcel className="print-excel-button" onClick={this.saveAsExcel} />
            <FaRegFilePdf className="print-pdf-button" onClick={this.exportPDF} />
          </div>

          <Table id="reportSummons" striped bordered hover size="sm" responsive="sm"
            className="report-table summons-report-table">
            <thead>
              <tr>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Date Created</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="dateCreate" />
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Client</Dropdown.Toggle>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Process</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.process}
                      onCheckSelected={this.onCheckSelected} filterDropdowns={this.filterDropdowns} field="process"/>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Court</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.courts}
                      onCheckSelected={this.onCheckSelected} filterDropdowns={this.filterDropdowns} field="court"/>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Ref Number</Dropdown.Toggle>
                    <Dropdown.Menu className="report-filter-dropdown-menu">
                      <Form.Control id="searchInput" type="text" onChange={e => this.onCheckSelected(e, {name: e.target.value}, "refNumber", true)} />
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Plaintiff</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.plaintiffs}
                      onCheckSelected={this.onCheckSelected} filterDropdowns={this.filterDropdowns} field="plaintiff"/>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Case Number</Dropdown.Toggle>
                    <Dropdown.Menu className="report-filter-dropdown-menu">
                      <Form.Control id="searchInput" type="text" onChange={e => this.onCheckSelected(e, {name: e.target.value}, "caseNumber", true)} />
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Batch</Dropdown.Toggle>
                    <Dropdown.Menu className="report-filter-dropdown-menu">
                      <Form.Control id="searchInput" type="text" onChange={e => this.onCheckSelected(e, {name: e.target.value}, "batchNumber", true)} />
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Issue Date</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="dateIssue" />
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Sheriff</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.sheriffs}
                      onCheckSelected={this.onCheckSelected} filterDropdowns={this.filterDropdowns} field="sheriff"/>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>To Sheriff</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="dateSheriff" />
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>To Client</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="dateClient" />
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>To Court</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="dateToCourt" />
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>From Court</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="dateFromCourt" />
                  </Dropdown>
                </th>
              </tr>
            </thead>
            <tbody>

              {this.state.reportSummons.map((row, index) => (
                <tr key={index}>

                  <td>{row.createdOn}</td>
                  <td>{row.client}</td>
                  <td>{row.process}</td>
                  <td>{row.court}</td>
                  <td>{row.refNumber}</td>
                  <td>{row.plaintiff}</td>
                  <td>{row.caseNumber}</td>
                  <td>{row.batchNumber}</td>
                  <td>{row.issueDate}</td>
                  <td>{row.sheriff}</td>
                  <td>{row.toSheriff}</td>
                  <td>{row.toClient}</td>
                  <td>{row.shipToCourt}</td>
                  <td>{row.shipFromCourt}</td>

                </tr>
              ))}

            </tbody>
          </Table>

        </div>

      </div>
    )
  }
}
