import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FaRegFilePdf } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import Form from "react-bootstrap/Form";

const PdfViewer = (props) => (
  <div style={{marginTop: "1rem"}}>
    <Accordion className="pdf-accordion">
      <Card>
        <Card.Header>
          <Accordion.Toggle className="notes-toggle-button" as={Button} variant="text" eventKey="0">
            Show Saved PDFs
          </Accordion.Toggle>
          <div style={{width: "100%"}}></div>
          <Button style={{display: props.pdfsSelected.length > 0 && "FileUpdate" in props.auth ? "" : "none" }} variant="danger"
            onClick={e => props.deleteSelectedPDFs()}>Delete</Button>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>

            {props.whatToShow.showSummons ?
              <div style={{display: "flex"}}>

                <Form style={{width: "50%"}}>

                  {props.files.savedFiles.filter(is => is.process === "IS").map((isFiltered, isindex) => (

                    <div key={isindex}
                      className="item-pdfList-form">
                      <Form.Check type="checkbox" id={isFiltered.name}>
                        <Form.Check.Input type="checkbox" onClick={e => props.addToDelete(e, isFiltered)}/>
                        <Form.Check.Label>
                          <FaRegFilePdf/>
                          <p>{isFiltered.name}</p>
                        </Form.Check.Label>
                      </Form.Check>

                      <FiDownload className="item-pdf-download" onClick={e => props.showPDFs(isFiltered)}/>
                    </div>

                  ))}

                </Form>

                <Form style={{width: "50%"}}>

                  {props.files.savedFiles.filter(sd => sd.process === "SD").map((sdFiltered, sdindex) => (

                    <div key={sdindex}
                      className="item-pdfList-form">
                      <Form.Check type="checkbox" id={sdFiltered.name}>
                        <Form.Check.Input type="checkbox" onClick={e => props.addToDelete(e, sdFiltered)}/>
                        <Form.Check.Label>
                          <FaRegFilePdf/>
                          <p>{sdFiltered.name}</p>
                        </Form.Check.Label>
                      </Form.Check>

                      <FiDownload className="item-pdf-download" onClick={e => props.showPDFs(sdFiltered)}/>
                    </div>

                  ))}

                </Form>

              </div>
            : ""}

            {props.whatToShow.showSheriff ?

              <div style={{display: "flex"}}>
                <Form style={{width: "50%"}}>

                  {props.files.savedFiles.filter(sr => sr.process === "SR").map((srFiltered, srindex) => (

                    <div key={srindex}
                      className="item-pdfList-form">
                      <Form.Check type="checkbox" id={srFiltered.name}>
                        <Form.Check.Input type="checkbox" onClick={e => props.addToDelete(e, srFiltered)}/>
                        <Form.Check.Label>
                          <FaRegFilePdf/>
                          <p>{srFiltered.name}</p>
                        </Form.Check.Label>
                      </Form.Check>

                      <FiDownload className="item-pdf-download" onClick={e => props.showPDFs(srFiltered)}/>
                    </div>

                  ))}

                </Form>
              </div>

            : ""}

            {props.whatToShow.showJudge ?

              <div style={{display: "flex"}}>

                <Form style={{width: "50%"}}>

                  {props.files.savedFiles.filter(pl => pl.process === "PL").map((plFiltered, plindex) => (

                    <div key={plindex}
                      className="item-pdfList-form">
                      <Form.Check type="checkbox" id={plFiltered.name}>
                        <Form.Check.Input type="checkbox" onClick={e => props.addToDelete(e, plFiltered)}/>
                        <Form.Check.Label>
                          <FaRegFilePdf/>
                          <p>{plFiltered.name}</p>
                        </Form.Check.Label>
                      </Form.Check>

                      <FiDownload className="item-pdf-download" onClick={e => props.showPDFs(plFiltered)}/>
                    </div>

                  ))}

                </Form>

                <Form style={{width: "50%"}}>

                  {props.files.savedFiles.filter(gq => gq.process === "GQ").map((gqFiltered, gqindex) => (

                    <div key={gqindex}
                      className="item-pdfList-form">
                      <Form.Check type="checkbox" id={gqFiltered.name}>
                        <Form.Check.Input type="checkbox" onClick={e => props.addToDelete(e, gqFiltered)}/>
                        <Form.Check.Label>
                          <FaRegFilePdf/>
                          <p>{gqFiltered.name}</p>
                        </Form.Check.Label>
                      </Form.Check>

                      <FiDownload className="item-pdf-download" onClick={e => props.showPDFs(gqFiltered)}/>
                    </div>

                  ))}

                </Form>

              </div>

            : ""}

            {props.whatToShow.showOther ?

              <div style={{display: "flex"}}>
                <Form style={{width: "50%"}}>

                  {props.files.savedFiles.filter(ot => ot.process === "OT").map((otFiltered, otindex) => (

                    <div key={otindex}
                      className="item-pdfList-form">
                      <Form.Check type="checkbox" id={otFiltered.name}>
                        <Form.Check.Input type="checkbox" onClick={e => props.addToDelete(e, otFiltered)}/>
                        <Form.Check.Label>
                          <FaRegFilePdf/>
                          <p>{otFiltered.name}</p>
                        </Form.Check.Label>
                      </Form.Check>

                      <FiDownload className="item-pdf-download" onClick={e => props.showPDFs(otFiltered)}/>
                    </div>

                  ))}

                </Form>
              </div>

            : ""}

          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>
);

export default PdfViewer;
